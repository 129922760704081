/* eslint-disable prettier/prettier */
import React, { useCallback } from "react";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { getFieldProps, inputProps } from "../../utils";
import { BuildMarkdownEditor } from "./Doubts";

const FAQ = ({ formik }) => {

  const buildTextField = (formik, placeholder, name, md = 5, xs = 11) => {
    return (
      <Grid item md={md} xs={xs}>
        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          InputProps={inputProps}
          label={placeholder}
          margin="none"
          variant="outlined"
          {...getFieldProps(formik, {
            name,
          })}
        />
      </Grid>
    );
  };


  const handleAddQuestion = useCallback(() => {
    const questions = formik.values.benefitquestions ?? [];
    const updatedValue = [
      ...questions,
      {
        idx: questions.length,
        text_default: {
          question: "",
          answer: "",
        },
        text_en: {
          question: "",
          answer: "",
        },
      },
    ];

    formik.setFieldValue("benefitquestions", updatedValue);
  }, [formik]);

  const handleDeleteQuestion = useCallback(
    (i) => () => {
      const newValue = [...(formik.values.benefitquestions || [])];
      newValue.splice(i, 1);
      formik.setFieldValue("benefitquestions", newValue);
    },
    [formik]
  );

  return (
    <Grid item xs={12}>
      <Grid item>
        <Typography variant="h6">
          Here instead you can upload additional information to help with
          your/your employee&apos;s concerns
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <>
          <Grid item>
            <Typography
              color="textSecondary"
              style={{ fontSize: "1rem" }}
              variant="h6"
            >
              Are there questions or concerns you are often asked? Fill out the
              <b> FAQs</b>
            </Typography>
          </Grid>
          {formik.values.benefitquestions?.map((elem, i) => {
            return (
              <React.Fragment key={i}>
                <Grid
                  item
                  style={{ marginTop: "0.75rem", marginBottom: "0.5rem" }}
                >
                  <Typography
                    color="textSecondary"
                    style={{ fontSize: "0.75rem" }}
                    variant="h6"
                  >
                    Write question:
                  </Typography>
                </Grid>
                <Grid container spacing={2}>
                  {buildTextField(
                    formik,
                    "Default language",
                    `benefitquestions.[${i}].text_default.question`
                  )}

                  {buildTextField(
                    formik,
                    "English",
                    `benefitquestions.[${i}].text_en.question`
                  )}
                  <Grid item md={2} xs={1}>
                    <IconButton onClick={handleDeleteQuestion(i)}>
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid item style={{ marginTop: "1.75rem" }}>
                  <Typography
                    color="textSecondary"
                    style={{ fontSize: "0.75rem" }}
                    variant="h6"
                  >
                    Response:
                  </Typography>
                </Grid>
                <Grid container spacing={2}>
                  <BuildMarkdownEditor
                    formik={formik}
                    name={`benefitquestions.[${i}].text_default.answer`}
                    placeholder={"Default language"}
                    value={
                      formik.values.benefitquestions[i]?.text_default?.answer ??
                      ""
                    }
                  />
                  <BuildMarkdownEditor
                    formik={formik}
                    name={`benefitquestions.[${i}].text_en.answer`}
                    placeholder={"English"}
                    value={
                      formik.values.benefitquestions[i]?.text_en?.answer ?? ""
                    }
                  />
                </Grid>
                {i < formik.values.benefitquestions?.length - 1 && (
                  <Divider
                    style={{ marginTop: "1rem", marginBottom: "1rem" }}
                  />
                )}
              </React.Fragment>
            );
          })}
          <Grid
            item
            style={{ marginBottom: "0.5rem", marginTop: "0.5rem" }}
            xs={12}
          >
            <Button
              color="primary"
              disabled={false}
              endIcon={<AddIcon />}
              variant="contained"
              onClick={handleAddQuestion}
            >
              Add question
            </Button>
          </Grid>
        </>
      </Grid>
    </Grid>
  );
};

export default FAQ;
