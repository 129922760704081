import React, { useCallback, useEffect } from "react";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  MenuItem,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import { getFieldProps, inputProps } from "../../utils";
import { DateTimePicker } from "@material-ui/pickers";
import InfoTooltip from "../../InfoTooltip";
import { useAppContext } from "../../../api/AppContext";
import ClearIcon from "@material-ui/icons/Clear";
import { BuildMarkdownEditor } from "./Doubts";

const KeyInfo = ({ formik }) => {
  const handleChange = useCallback(() => {
    formik.setFieldValue(
      "userCanGiveFeedback",
      !formik.values.userCanGiveFeedback
    );
    if (!formik.values.userCanGiveFeedback) {
      formik.setFieldValue("freeComment", false);
      formik.setFieldValue("questionAboutUsed", false);
    }
  }, [formik]);

  const handleBookingChange = useCallback(
    (viaBookingSystem) => {
      if (viaBookingSystem) {
        formik.setFieldValue("keyInfo.sites", [""]);
        formik.setFieldValue("booking_id", formik.values.booking_id);
      } else {
        formik.setFieldValue("booking_id", null);
      }
    },
    [formik]
  );

  const buildTextField = (formik, placeholder, name, md = 6, xs = 12) => {
    return (
      <Grid item md={md} xs={xs}>
        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          InputProps={inputProps}
          label={placeholder}
          margin="none"
          variant="outlined"
          {...getFieldProps(formik, {
            name,
          })}
        />
      </Grid>
    );
  };

  const handleExpirationDateChange = useCallback(
    (value) => {
      formik.setFieldValue("keyInfo.expirationDate", value);
      formik.setFieldTouched("keyInfo.expirationDate", true);
    },
    [formik]
  );

  const handleClearDate = useCallback(
    (e) => {
      handleExpirationDateChange(null);
      e.stopPropagation();
    },
    [handleExpirationDateChange]
  );

  const { fetchWelfareBookings, welfareBookings } = useAppContext();

  useEffect(() => {
    fetchWelfareBookings();
  }, [fetchWelfareBookings]);

  return (
    <Grid item xs={12}>
      <Grid item>
        <Typography variant="h6">
          We are almost at the end! Summarize the key information of the
          benefit!
        </Typography>
      </Grid>
      <Grid item style={{ margin: "1.75rem 0 0.5rem 0" }} xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={formik.values.viaBookingSystem}
              color="primary"
              name="viaBookingSystem"
              onBlur={formik.handleBlur}
              onChange={(e) => {
                formik.handleChange(e);
                handleBookingChange(e.target.checked);
              }}
            />
          }
          label="Is it via Booking system?"
        />
      </Grid>
      <Grid item xs={12}>
        <>
          {formik.values.viaBookingSystem ? (
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                select
                InputLabelProps={{ shrink: true }}
                error={
                  formik.touched.booking_id && Boolean(formik.errors.booking_id)
                }
                helperText={
                  formik.touched.booking_id && formik.errors.booking_id
                }
                label="Bookings"
                margin="none"
                name="booking_id"
                value={formik.values.booking_id}
                variant="outlined"
                onChange={formik.handleChange}
              >
                {welfareBookings
                  ?.filter((booking) => !booking.isdeleted)
                  .map((booking) => (
                    <MenuItem key={booking.id} value={booking.id}>
                      {booking.name}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
          ) : (
            <>
              <Grid item style={{ marginBottom: "1rem", marginTop: "1rem" }}>
                <Typography
                  color="textSecondary"
                  style={{ fontSize: "1rem" }}
                  variant="h6"
                >
                  Add the dedicated site link to access the benefit!
                </Typography>
              </Grid>

              {formik.values.keyInfo?.sites?.map((_, i) => (
                <React.Fragment key={i}>
                  <Grid container spacing={2}>
                    {buildTextField(
                      formik,
                      "Write link",
                      `keyInfo.sites[${i}]`
                    )}

                    <Grid item md={6} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values.openInWecare}
                            color="primary"
                            name="openInWecare"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                          />
                        }
                        label="Open this link within WeCare"
                      />
                      {/* <IconButton onClick={() => handleDelete(i, "sites")}>
              <DeleteIcon />
            </IconButton> */}
                    </Grid>
                  </Grid>
                </React.Fragment>
              ))}
            </>
          )}
          <Grid item md={6} style={{ marginTop: "1.75rem" }} xs={12}>
            <Grid container alignItems="center">
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.userCanSubscribe}
                      color="primary"
                      name="userCanSubscribe"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                  }
                  label="User can subscribe to the welfare"
                />
              </Grid>
              <Grid item>
                <InfoTooltip
                  text="If checked, by default all users are subscribed; user can unsubscribe/subscribe. If unchecked, subscription feature is disabled for everyone."
                  title="User can subscribe to the welfare"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ marginTop: "1.75rem" }} xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.userCanGiveFeedback}
                  color="primary"
                  name="userCanGiveFeedback"
                  onBlur={formik.handleBlur}
                  onChange={handleChange}
                />
              }
              label="User can give feedback"
            />
          </Grid>
          {formik.values.userCanGiveFeedback && (
            <>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.questionAboutUsed}
                      color="primary"
                      name="questionAboutUsed"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                  }
                  label="Ask if the user used the benefit"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.freeComment}
                      color="primary"
                      name="freeComment"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                  }
                  label="Allow free comment"
                />
              </Grid>
            </>
          )}
          <Grid item style={{ marginBottom: "0.5rem", marginTop: "1rem" }}>
            <Typography
              color="textSecondary"
              style={{ fontSize: "1rem" }}
              variant="h6"
            >
              Does the benefit have a deadline to activate or redeem it?
            </Typography>
          </Grid>
          <Grid item lg={3} md={3} xs={12}>
            <DateTimePicker
              autoOk
              clearable
              disablePast
              fullWidth
              ampm={false}
              format="dd MMMM yyyy HH:mm"
              inputVariant="outlined"
              label={""}
              {...getFieldProps(formik, { name: "keyInfo.expirationDate" })}
              InputLabelProps={{
                shrink: true,
                style: { pointerEvents: "all" },
              }}
              InputProps={{
                endAdornment: formik.values.keyInfo?.expirationDate ? (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={handleClearDate}>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ) : null,
              }}
              variant="inline"
              onChange={handleExpirationDateChange}
            />
          </Grid>
          <Grid item style={{ marginBottom: "1rem", marginTop: "2rem" }}>
            <Typography
              color="textSecondary"
              style={{ fontSize: "1rem" }}
              variant="h6"
            >
              Who is the contact (e.g., email) reference in Angelini?
            </Typography>
          </Grid>
          {formik.values.keyInfo?.contacts?.map((_, i) => {
            return (
              <React.Fragment key={i}>
                <Grid container spacing={2}>
                  {buildTextField(
                    formik,
                    "Write contact",
                    `keyInfo.contacts[${i}]`
                  )}

                  {/* <Grid item md={2} xs={1}>
                    <IconButton onClick={() => handleDelete(i, "contacts")}>
                      <DeleteIcon />
                    </IconButton>
                  </Grid> */}
                </Grid>
              </React.Fragment>
            );
          })}
          {/* <Grid
            item
            style={{ marginBottom: "0.5rem", marginTop: "0.5rem" }}
            xs={12}
          >
            <Button
              color="primary"
              disabled={false}
              endIcon={<AddIcon />}
              variant="contained"
              onClick={() => handleAdd("contacts")}
            >
              Add contact
            </Button>
          </Grid> */}

          <Grid item style={{ marginBottom: "1rem", marginTop: "2rem" }}>
            <Typography
              color="textSecondary"
              style={{ fontSize: "1rem" }}
              variant="h6"
            >
              Add support contact! Both internal to Angelini and external ones!
            </Typography>
          </Grid>
          {formik.values.keyInfo?.supportContacts?.map((_, i) => {
            return (
              <React.Fragment key={i}>
                <Grid container spacing={2}>
                  {buildTextField(
                    formik,
                    "Add support contact",
                    `keyInfo.supportContacts[${i}]`
                  )}

                  {/* <Grid item md={2} xs={1}>
                    <IconButton
                      onClick={() => handleDelete(i, "supportContacts")}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid> */}
                </Grid>
              </React.Fragment>
            );
          })}
          <Grid item style={{ marginBottom: "1rem", marginTop: "2rem" }}>
            <Typography
              color="textSecondary"
              style={{ fontSize: "1rem" }}
              variant="h6"
            >
              Who is managing the benefit? (e.g., external or in-house
              provider?).
            </Typography>
          </Grid>
          <Grid container spacing={2}>
            {buildTextField(formik, "Write names", `keyInfo.manager`)}
          </Grid>
          <Grid item style={{ marginTop: "2rem" }}>
            <Typography
              color="textSecondary"
              style={{ fontSize: "1rem" }}
              variant="h6"
            >
              [For WeCare Treasure Hunt initiative] Riddle for this benefit
            </Typography>
          </Grid>
          <Grid container spacing={2}>
            <BuildMarkdownEditor
              formik={formik}
              maxLength={160}
              name={`text_default.riddle`}
              placeholder={"Default language"}
              value={formik.values.text_default?.riddle}
            />
            <BuildMarkdownEditor
              formik={formik}
              maxLength={160}
              name={`text_en.riddle`}
              placeholder={"English"}
              value={formik.values.text_en?.riddle}
            />
          </Grid>
          <Grid item style={{ marginTop: "2rem" }}>
            <Typography
              color="textSecondary"
              style={{ fontSize: "1rem" }}
              variant="h6"
            >
              [For WeCare Treasure Hunt initiative] Hint of the riddle for this
              benefit
            </Typography>
          </Grid>
          <Grid container spacing={2}>
            <BuildMarkdownEditor
              formik={formik}
              maxLength={160}
              name={`text_default.riddle_hint`}
              placeholder={"Default language"}
              value={formik.values.text_default?.riddle_hint}
            />
            <BuildMarkdownEditor
              formik={formik}
              maxLength={160}
              name={`text_en.riddle_hint`}
              placeholder={"English"}
              value={formik.values.text_en?.riddle_hint}
            />
          </Grid>

          {/* <Grid
            item
            style={{ marginBottom: "0.5rem", marginTop: "0.5rem" }}
            xs={12}
          >
            <Button
              color="primary"
              disabled={false}
              endIcon={<AddIcon />}
              variant="contained"
              onClick={() => handleAdd("supportContacts")}
            >
              Add support contact
            </Button>
          </Grid> */}

          {/* <Grid
            item
            style={{ marginBottom: "0.5rem", marginTop: "0.5rem" }}
            xs={12}
          >
            <Button
              color="primary"
              disabled={false}
              endIcon={<AddIcon />}
              variant="contained"
              onClick={() => handleAdd("sites")}
            >
              Add link
            </Button>
          </Grid> */}
          {/* <Grid item style={{ marginBottom: "1rem", marginTop: "1rem" }}>
            <Typography color="textSecondary" variant="h6">
              Are there any other useful links?
            </Typography>
          </Grid>
          {formik.values.keyInfo?.usefullLinks?.map((_, i) => {
            return (
              <React.Fragment key={i}>
                <Grid container spacing={2}>
                  {buildTextField(
                    formik,
                    "Write link",
                    `keyInfo.usefullLinks[${i}]`
                  )}

                   <Grid item md={2} xs={1}>
                    <IconButton onClick={() => handleDelete(i, "usefullLinks")}>
                      <DeleteIcon />
                    </IconButton>
                  </Grid> *
                </Grid>
              </React.Fragment>
            );
          })}
           <Grid
            item
            style={{ marginBottom: "0.5rem", marginTop: "0.5rem" }}
            xs={12}
          >
            <Button
              color="primary"
              disabled={false}
              endIcon={<AddIcon />}
              variant="contained"
              onClick={() => handleAdd("usefullLinks")}
            >
              Add link
            </Button>
          </Grid>  */}
        </>
      </Grid>
    </Grid>
  );
};

export default KeyInfo;
