/* eslint-disable prettier/prettier */
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  LEGACY_TEMPLATEFAMILY_ID,
  NO_PREVIEW_ID,
} from "../shared/constConfig/config.js";
import orderBy from "lodash/orderBy";
import InfoTooltip from "../InfoTooltip";
import get from "lodash/get";
import removeMarkdown from "remove-markdown";
import { useFormik } from "formik";
import Sticky from "react-sticky-el";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import { draftToMarkdown, markdownToDraft } from "markdown-draft-js";
import Highlighter from "react-highlight-words";

import Box from "@material-ui/core/Box";
import { Checkbox, FormControlLabel, useTheme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import Alert from "@material-ui/lab/Alert";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import MuiToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { useSnackbar } from "notistack";

import AddIcon from "@material-ui/icons/Add";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

import Preview from "../Preview";
import ImageUpload from "../ImageUpload";
import Input from "../shared/Input";
import Loader from "../shared/Loader";
import LocaleSelect from "../shared/LocaleSelect";
import MarkdownEditor from "../shared/MarkdownEditor";
import OutlinedButton from "../OutlinedButton";
import Timestamps from "../Timestamps";
import useConfirmLeave from "../../hooks/useConfirmLeave";
import { getFieldProps, removeEmpty } from "../utils";
import { useAppContext } from "../../api/AppContext";
import { useModals } from "../../hooks/useModals";
import { contentTypesConfig } from "../Journey/contentTypes";
import useModal from "../../hooks/useModal";
import useStickyHeight from "../../hooks/useStickyHeight";
import SpecialConditions from "./SpecialConditions";
import getData from "../../lib/getData";
import {
  getScheduledSlotName,
  getScheduledSlotDatetimeIntervalString,
  getScheduledSlotSpecificIntervalString,
} from "../../lib/scheduledSlotUtilities";
// import {
//   interactionPeriods,
//   formatScheduledSlot,
// } from "../../lib/interactionPeriods";
// import format from "date-fns/format";
import UserStatePathSelect from "./UserStatePathSelect";
import TemplateFamilySelect from "./TemplateFamilySelect";
import CallBehaviourSelect, {
  getCallBehaviourOptions,
} from "../shared/CallBehaviourSelect";
import SemanticTemplateSelect from "./SemanticTemplateSelect";
// import SemanticPreviewTemplateSelect from "./SemanticPreviewTemplateSelect";
import JourneysSelect from "../shared/JourneysSelect";
import Link from "@material-ui/core/Link";
import AlertWithShowMore from "../AlertWithShowMore";
import SaveButtons from "../SaveButtons";
import FontFamilySelect from "../shared/FontFamilySelect.js";
// import GetAppIcon from "@material-ui/icons/GetApp";
import ImageIcon from "@material-ui/icons/Image";
import useLocalStorage from "../../hooks/useLocalStorage.js";
import AiIntegration from "./AiIntegration.js";

// import AnalyticsReport from "../AnalyticsReport/index";
const SelectorAlignedTypography = withStyles({
  root: {
    lineHeight: "2",
  },
})(Typography);

const Title = withStyles({
  root: {
    fontWeight: "bold",
    marginBottom: 6,
  },
})(Typography);

const SecondsSelector = withStyles({
  root: {
    paddingRight: 8,
    width: 120,
  },
})(TextField);

const SecondsSelectorWithinTypography = withStyles({
  root: {
    paddingLeft: 8,
  },
})(SecondsSelector);

const TimesSelector = withStyles({
  root: {
    paddingRight: 8,
    width: 120,
  },
})(TextField);

const Subtitle = withStyles({
  root: {
    fontSize: "14px",
  },
})(Typography);

const ToggleButton = withStyles(({ spacing }) => ({
  root: {
    paddingLeft: spacing(2),
    paddingRight: spacing(2),
  },
}))(MuiToggleButton);

const CTATypeSelect = ({ types, value, ...props }) => (
  <TextField
    fullWidth
    select
    InputLabelProps={{ shrink: true }}
    label="CTA action"
    placeholder="Select action type"
    variant="outlined"
    {...props}
    value={value || (!types || types.includes("Close") ? "Close" : value)}
  >
    {(!types || types.includes("Semantic")) && (
      <MenuItem value="Semantic">Semantic flow (next semantic)</MenuItem>
    )}
    {(!types || types.includes("Dashboard Material")) && (
      <MenuItem value="Dashboard Material">
        Link a material in Dashboard
      </MenuItem>
    )}
    {(!types || types.includes("External Link")) && (
      <MenuItem value="External Link">Link an external material</MenuItem>
    )}
    {(!types || types.includes("Special Link")) && (
      <MenuItem value="Special Link">Special link</MenuItem>
    )}
    {(!types || types.includes("Welfare")) && (
      <MenuItem value="Welfare">Link a WeCare Welfare</MenuItem>
    )}
    {(!types || types.includes("News")) && (
      <MenuItem value="News">Link a WeCare News</MenuItem>
    )}
    {(!types || types.includes("Dashboard Step")) && (
      <MenuItem value="Dashboard Step">Step in dashboard</MenuItem>
    )}
    {(!types || types.includes("Survey")) && (
      <MenuItem value="Survey">Survey</MenuItem>
    )}
    {(!types || types.includes("Close")) && (
      <MenuItem value="Close">Close tip</MenuItem>
    )}

    {(!types || types.includes("Prompt Tool")) && (
      <MenuItem value="Prompt Tool">Link a prompt of fame Tool</MenuItem>
    )}

    {(!types || types.includes("Prompt")) && (
      <MenuItem value="Prompt">Link a prompt of fame Prompt</MenuItem>
    )}
  </TextField>
);

// const TriggerSelect = ({ triggers, uiConfigs, ...props }) => (
//   <TextField
//     fullWidth
//     select
//     InputLabelProps={{ shrink: true }}
//     label="Trigger"
//     placeholder="Select trigger"
//     variant="outlined"
//     {...props}
//   >
//     {triggers.map(({ stimulusId, name, uiConfig }) => (
//       <MenuItem key={stimulusId} value={stimulusId}>
//         {name || "-"} (
//         {uiConfig?.anchor ||
//           uiConfigs[stimulusId]?.anchor ||
//           "notification_area"}
//         )
//       </MenuItem>
//     ))}
//   </TextField>
// );

const CTA = ({
  formik,
  index,
  isDisabled,
  materials,
  interactions,
  isMaterialsLoading,
  isSpecialLinksLoading,
  specialLinks,
  handleCreateMaterial,
  handleCreateSpecialLink,
  types,
  steps,
  surveys,
  isListJourneyBenefitsLoading,
  journeyBenefits,
  journeyNews,
  isListJourneyNewsLoading,
  isSurveysLoading,
  fieldName = "Button",
  ctaTypeFieldName = `cta${index || 1}Type`,
  ctaMaterialFieldName = `cta${index || 1}LinkId`,
  ctaTargetFieldName = `cta${index || 1}Target`,
  ctaWelfareFieldName = `cta${index || 1}LinkId`,
  isCTATitleShown = true,
  tools,
  prompts,
  aiResult,
}) => {
  const indexString = index ? `${fieldName} ${index}` : fieldName;
  const type = get(formik.values, ctaTypeFieldName);

  const handleLinkedMaterialChange = useCallback(
    (e, value) => {
      if (value.props.value === "new") {
        return;
      }
      formik.setFieldValue(ctaMaterialFieldName, value.props.value);
      formik.setFieldTouched(ctaMaterialFieldName, true);
    },
    [formik, index]
  );

  const handleLinkedWelfareChange = useCallback(
    (e, value) => {
      if (value.props.value === "new") {
        return;
      }
      formik.setFieldValue(ctaWelfareFieldName, value.props.value);
      formik.setFieldTouched(ctaWelfareFieldName, true);
    },
    [formik, index]
  );

  const handleNextInteractionChange = useCallback(
    (e, value) => {
      formik.setFieldValue(ctaTargetFieldName, value.props.value);
      formik.setFieldTouched(ctaTargetFieldName, true);
    },
    [formik, index]
  );

  const handleCTATypeChange = useCallback(
    (e) => {
      formik.setFieldValue(ctaTypeFieldName, e.target.value);
      formik.setFieldTouched(ctaTypeFieldName, true);
      formik.setFieldValue(ctaMaterialFieldName, undefined);
      formik.setFieldTouched(ctaMaterialFieldName, true);
      formik.setFieldValue(ctaWelfareFieldName, undefined); // linkId
      formik.setFieldTouched(ctaWelfareFieldName, true);
    },
    [formik, ctaTypeFieldName, ctaMaterialFieldName, ctaWelfareFieldName]
  );

  return (
    <Grid container spacing={4}>
      {(!types || types.length > 1) && (
        <Grid item xs={isCTATitleShown || (type && type !== "Close") ? 6 : 12}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <CTATypeSelect
                label={`${indexString} action`}
                {...getFieldProps(formik, {
                  disabled: isDisabled,
                  name: ctaTypeFieldName,
                })}
                types={types}
                onChange={handleCTATypeChange}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item xs={!types || types.length > 1 ? 6 : 12}>
        <Grid container spacing={4}>
          {isCTATitleShown && (
            <Grid item xs={12}>
              <Input
                label={`${indexString} text`}
                maxLength={25}
                placeholder="Be accurate, specific, explicit. Don't be vague."
                {...getFieldProps(formik, {
                  disabled: isDisabled,
                  name: `cta${index || 1}Text`,
                })}
              />
            </Grid>
          )}
          {type && type !== "Close" && (!types || types.length > 1) && (
            <Grid item xs={12}>
              {type === "External Link" && (
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label={`${indexString} link`}
                  placeholder="https://"
                  variant="outlined"
                  {...getFieldProps(formik, {
                    disabled: isDisabled,
                    name: ctaTargetFieldName,
                  })}
                />
              )}
              {type === "Semantic" && (
                <TextField
                  fullWidth
                  select
                  InputLabelProps={{ shrink: true }}
                  label={`Next ${
                    formik.values.type === "PRIME" ? "tip" : "nudge"
                  }`}
                  placeholder={`Select next ${
                    formik.values.type === "PRIME" ? "tip" : "nudge"
                  }`}
                  {...getFieldProps(formik, {
                    name: ctaTargetFieldName,
                    isDisabled,
                  })}
                  variant="outlined"
                  onChange={handleNextInteractionChange}
                >
                  {interactions
                    ?.filter(({ id }) => id !== formik.values.id)
                    .map(({ id, title, text }) => (
                      <MenuItem key={id} value={id}>
                        <ListItemText primary={title} secondary={text} />
                      </MenuItem>
                    ))}
                </TextField>
              )}
              {type === "Dashboard Material" && (
                <TextField
                  fullWidth
                  select
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="end">
                        {isMaterialsLoading && <CircularProgress size={25} />}
                      </InputAdornment>
                    ),
                  }}
                  label="Learning material"
                  placeholder="Select Material"
                  {...getFieldProps(formik, {
                    name: ctaMaterialFieldName,
                    isDisabled: isMaterialsLoading || isDisabled,
                  })}
                  variant="outlined"
                  onChange={handleLinkedMaterialChange}
                >
                  <MenuItem value="new" onClick={handleCreateMaterial}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <ListItemText primary="Add new material" />
                      <Box alignItems="center" display="flex">
                        <AddIcon />
                      </Box>
                    </Box>
                  </MenuItem>
                  <Divider />
                  {materials?.map(({ id, name, description }) => (
                    <MenuItem key={id} value={id}>
                      <ListItemText primary={name} secondary={description} />
                    </MenuItem>
                  ))}
                </TextField>
              )}
              {type === "Welfare" && (
                <TextField
                  fullWidth
                  select
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="end">
                        {isListJourneyBenefitsLoading && (
                          <CircularProgress size={25} />
                        )}
                      </InputAdornment>
                    ),
                  }}
                  label="Welfares"
                  placeholder="Select Welfare"
                  {...getFieldProps(formik, {
                    isDisabled: isListJourneyBenefitsLoading || isDisabled,
                    name: ctaWelfareFieldName,
                  })}
                  variant="outlined"
                  onChange={handleLinkedWelfareChange}
                >
                  <Divider />
                  {journeyBenefits?.map(({ id, name, description }) => (
                    <MenuItem key={id} value={id}>
                      <ListItemText primary={name} secondary={description} />
                    </MenuItem>
                  ))}
                </TextField>
              )}
              {type === "News" && (
                <TextField
                  fullWidth
                  select
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="end">
                        {isListJourneyNewsLoading && (
                          <CircularProgress size={25} />
                        )}
                      </InputAdornment>
                    ),
                  }}
                  label="News"
                  placeholder="Select News"
                  {...getFieldProps(formik, {
                    isDisabled: isListJourneyNewsLoading || isDisabled,
                    name: ctaWelfareFieldName,
                  })}
                  variant="outlined"
                  onChange={handleLinkedWelfareChange}
                >
                  <Divider />
                  {journeyNews?.map(({ id, text_default }) => (
                    <MenuItem key={id} value={id}>
                      <ListItemText
                        primary={text_default?.title}
                        secondary={text_default?.summary}
                      />
                    </MenuItem>
                  ))}
                </TextField>
              )}
              {type === "Special Link" && (
                <TextField
                  fullWidth
                  select
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="end">
                        {isSpecialLinksLoading && (
                          <CircularProgress size={25} />
                        )}
                      </InputAdornment>
                    ),
                  }}
                  label="Special Link"
                  placeholder="Select Special Link"
                  variant="outlined"
                  {...getFieldProps(formik, {
                    name: ctaMaterialFieldName,
                    disabled: isSpecialLinksLoading || isDisabled,
                  })}
                  onChange={handleLinkedMaterialChange}
                >
                  <MenuItem value="new" onClick={handleCreateSpecialLink}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <ListItemText primary="Add new special link" />
                      <Box alignItems="center" display="flex">
                        <AddIcon />
                      </Box>
                    </Box>
                  </MenuItem>
                  <Divider />
                  {specialLinks?.map(({ id, name, url }) => (
                    <MenuItem key={id} value={id}>
                      <ListItemText primary={name} secondary={url} />
                    </MenuItem>
                  ))}
                </TextField>
              )}
              {type === "Dashboard Step" && (
                <TextField
                  fullWidth
                  select
                  InputLabelProps={{ shrink: true }}
                  label="Dashboard step"
                  placeholder="Select dashboard step"
                  variant="outlined"
                  {...getFieldProps(formik, {
                    name: ctaMaterialFieldName,
                    disabled: isDisabled,
                  })}
                  onChange={handleLinkedMaterialChange}
                >
                  {steps.map(({ id, name, description }) => (
                    <MenuItem key={id} value={id}>
                      <ListItemText primary={name} secondary={description} />
                    </MenuItem>
                  ))}
                </TextField>
              )}

              {type === "Prompt Tool" && (
                <TextField
                  fullWidth
                  select
                  InputLabelProps={{ shrink: true }}
                  label="Prompt Tool"
                  placeholder="Select tool"
                  variant="outlined"
                  {...getFieldProps(formik, {
                    disabled: isDisabled,
                    name: ctaMaterialFieldName,
                  })}
                  onChange={handleLinkedMaterialChange}
                >
                  {tools.map((tool) => (
                    <MenuItem key={tool.id} value={tool.toolname}>
                      <ListItemText primary={tool.toolname} />
                    </MenuItem>
                  ))}
                </TextField>
              )}

              {type === "Prompt" && (
                <TextField
                  fullWidth
                  select
                  InputLabelProps={{ shrink: true }}
                  label="Prompt"
                  placeholder="Select prompt"
                  variant="outlined"
                  {...getFieldProps(formik, {
                    disabled: isDisabled,
                    name: ctaMaterialFieldName,
                  })}
                  onChange={handleLinkedMaterialChange}
                >
                  {prompts.map((prompt) => (
                    <MenuItem key={prompt.id} value={prompt.id}>
                      <ListItemText primary={prompt.title.it} />
                    </MenuItem>
                  ))}
                </TextField>
              )}
              {type === "Survey" && (
                <TextField
                  fullWidth
                  select
                  InputLabelProps={{ shrink: true }}
                  label="Survey"
                  placeholder="Select survey"
                  variant="outlined"
                  {...getFieldProps(formik, {
                    name: ctaMaterialFieldName,
                    disabled: isDisabled,
                  })}
                  InputProps={{
                    startAdornment: isSurveysLoading && (
                      <InputAdornment position="end">
                        <CircularProgress size={25} />
                      </InputAdornment>
                    ),
                  }}
                  onChange={handleLinkedMaterialChange}
                >
                  {surveys
                    .filter((survey) => survey.type !== "hi")
                    .map(({ id, name, description }) => (
                      <MenuItem key={id} value={id}>
                        <ListItemText primary={name} secondary={description} />
                      </MenuItem>
                    ))}
                </TextField>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

const validationSchema = (interaction, days) =>
  Yup.object().shape({
    afterCallDelay: Yup.number().when("callBehaviour", {
      is: "delay",
      then: Yup.number()
        .integer()
        .min(0, "Must be greater than or equal to 0")
        .required("required"),
    }),
    callBehaviour: Yup.string().nullable(),

    semanticTemplateId: Yup.string().when("viewType", {
      is: "client",
      then: Yup.string()
        .nullable()
        .required("Card Template is a required field"),
      otherwise: Yup.string().nullable(),
    }),
    semanticPreview: Yup.object().when("isFirstInFlow", {
      is: true,
      then: Yup.object().shape({
        semanticPreviewTemplateId: Yup.string()
          .nullable()
          .required("Preview Template is a required field"),
      }),
    }),
    callRelatedCustomization: Yup.boolean().nullable(),
    conditions: Yup.array().of(
      Yup.object({
        type: Yup.mixed()
          .oneOf([
            "hour",
            "weekday",
            "day",
            "month",
            "activeDays",
            "userStatePath",
            "survey",
            "subscribedToWelfare",
          ])
          .required(),
        values: Yup.mixed().when("type", {
          is: (type) =>
            ![
              "hour",
              "activeDays",
              "userStatePath",
              "survey",
              "subscribedToWelfare",
            ].includes(type),
          then: Yup.array().min(1).required(),
          otherwise: Yup.string().nullable(),
        }),
        from: Yup.mixed().when("type", {
          is: (type) => type === "hour",
          then: Yup.object({
            hours: Yup.number().min(0).max(23),
            minutes: Yup.number().min(0).max(59),
          })
            .nullable()
            .required(),
          otherwise: Yup.number().when("type", {
            is: (type) => type === "activeDays",
            then: Yup.number()
              .min(1)
              .max(
                days + 1,
                `This Journey is ${days} days long. You cannot exceed ${
                  days + 1
                } days.`
              ),
          }),
        }),
        to: Yup.mixed().when("type", {
          is: (type) => type === "hour",
          then: Yup.object({
            hours: Yup.number().min(0).max(23),
            minutes: Yup.number().min(0).max(59),
          })
            .nullable()
            .required(),
          otherwise: Yup.number().when("type", {
            is: (type) => type === "activeDays",
            then: Yup.number()
              .min(1)
              .max(
                days + 1,
                `This Journey is ${days} days long. You cannot exceed ${
                  days + 1
                } days.`
              ),
          }),
        }),
      })
    ),
    cta1LinkId: Yup.mixed()
      .when("cta1Type", {
        is: (type) => ["Special Link"].includes(type),
        then: Yup.string().required("Special link is a required field"),
      })
      .when("cta1Type", {
        is: (type) => ["Welfare"].includes(type),
        then: Yup.string().required("Welfare is a required field"),
      })
      .when("cta1Type", {
        is: (type) => ["News"].includes(type),
        then: Yup.string().required("News is a required field"),
      })
      .when("cta1Type", {
        is: (type) => ["Prompt Tool"].includes(type),
        then: Yup.string().required("Prompt Tool is a required field"),
      })
      .when("cta1Type", {
        is: (type) => ["Prompt"].includes(type),
        then: Yup.string().required("Prompt is a required field"),
      }),
    cta2LinkId: Yup.mixed()
      .when("cta2Type", {
        is: (type) => ["Special Link"].includes(type),
        then: Yup.string().required("Special link is a required field"),
      })
      .when("cta2Type", {
        is: (type) => ["Welfare"].includes(type),
        then: Yup.string().required("Welfare is a required field"),
      })
      .when("cta2Type", {
        is: (type) => ["News"].includes(type),
        then: Yup.string().required("News is a required field"),
      })
      .when("cta2Type", {
        is: (type) => ["Prompt Tool"].includes(type),
        then: Yup.string().required("Prompt Tool is a required field"),
      })
      .when("cta2Type", {
        is: (type) => ["Prompt"].includes(type),
        then: Yup.string().required("Prompt is a required field"),
      }),
    miniTipAutoProgress: Yup.boolean().nullable(),
    miniTipAutoProgressDelay: Yup.number().when("miniTipAutoProgress", {
      is: true,
      then: Yup.number()
        .integer()
        .min(0, "Must be greater than or equal to 0")
        .required("required"),
    }),
    miniTipAutoProgressOnlyIfIgnored: Yup.boolean().nullable(),
    miniTipAutoProgressOnlyIfIgnoredTimes: Yup.number().when(
      "miniTipAutoProgressOnlyIfIgnored",
      {
        is: true,
        then: Yup.number()
          .integer()
          .min(1, "Must be greater than or equal to 1")
          .required("required"),
      }
    ),
    dropdownData: Yup.array().of(
      Yup.object({
        label: Yup.string().required("Required field"),
        value: Yup.string().required("Value is a required field"),
      })
    ),
    tipgroup: Yup.string().nullable(),
    viewType: Yup.string(),
    teams: Yup.object().when("viewType", {
      is: "teams_app",
      then: Yup.object()
        .shape({
          backgrColorImage: Yup.string().nullable(),
          backgroundColor: Yup.string().nullable(),
          backgroundImage: Yup.string().nullable(),
          horizontalAlignment: Yup.string().nullable(),
          imageHeight: Yup.string().nullable(),
          imageWidth: Yup.string().nullable(),
          mainImage: Yup.string().nullable(),
          semanticTemplateId: Yup.string().required(
            "Card Template is a required field"
          ),
          size: Yup.string().nullable(),
          templateFamilyId: Yup.string().required(
            "Family Template is a required field"
          ),
          textColor: Yup.string().nullable(),
          textSize: Yup.string().nullable(),
          titleColor: Yup.string().nullable(),
          titleSize: Yup.string().nullable(),
        })
        .nullable(),
    }),
  });

const filterOptions = createFilterOptions({
  stringify: (option) => `${option.name} ${option.day} ${option.date}`,
});

const Option = ({
  aiResult,
  formik,
  isDisabled,
  onDropdownDataChange,
  onDropdownDataDelete,
  label,
  value,
  i,
  journeys,
  locale,
  materials,
  steps,
  surveys,
  tools,
  prompts,
  isListJourneyBenefitsLoading,
  journeyBenefits,
  journeyNews,
  isListJourneyNewsLoading,
  isSurveysLoading,
  specialLinks,
  interactions,
  isMaterialsLoading,
  isSpecialLinksLoading,
  onCreateMaterial,
  onCreateSpecialLink,
  isDeleteEnabled = true,
}) => {
  return (
    <Fragment>
      <Grid item md={3} xs={12}>
        <Input
          {...getFieldProps(formik, {
            name: `dropdownData.${i + 1}.label`,
          })}
          fullWidth
          required
          InputLabelProps={{ shrink: true }}
          disabled={isDisabled}
          label={`Option ${i + 1} text`}
          //maxLength={25}
          value={label}
          variant="outlined"
          onChange={onDropdownDataChange(i + 1, "label")}
        />
      </Grid>
      <Grid item md={9} xs={12}>
        <Grid container spacing={4}>
          <Grid item md={4} xs={12}>
            {(formik.values.userStatePath === "currentJourney" && (
              <JourneysSelect
                disableClearable
                {...getFieldProps(formik, {
                  name: `dropdownData.${i + 1}.value`,
                  helperText:
                    "Select the current Journey if you don't want to have user changing Journey",
                })}
                journeys={journeys}
                label="Journey"
                lable="Journey"
                value={value}
                onChange={onDropdownDataChange(i + 1, "value")}
              />
            )) ||
              (formik.values.userStatePath === "locale" && (
                <LocaleSelect
                  required
                  label="Language"
                  {...getFieldProps(formik, {
                    name: `dropdownData.${i + 1}.value`,
                    helperText: "Select the language",
                  })}
                  value={value}
                  onChange={onDropdownDataChange(i + 1, "value")}
                />
              )) || (
                <TextField
                  {...getFieldProps(formik, {
                    name: `dropdownData.${i + 1}.value`,
                  })}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  disabled={isDisabled}
                  label={`Option ${i + 1} value`}
                  value={value}
                  variant="outlined"
                  onChange={onDropdownDataChange(i + 1, "value")}
                />
              )}
          </Grid>
          <Grid item style={{ flex: 1, display: "" }}>
            <CTA
              ctaMaterialFieldName={`dropdownData.${i + 1}.ctaTarget`}
              ctaTargetFieldName={`dropdownData.${i + 1}.ctaTarget`}
              ctaTypeFieldName={`dropdownData.${i + 1}.ctaType`}
              ctaWelfareFieldName={`dropdownData.${i + 1}.ctaTarget`}
              fieldName="Option"
              formik={formik}
              handleCreateMaterial={onCreateMaterial}
              handleCreateSpecialLink={onCreateSpecialLink}
              index={i + 1}
              interactions={interactions}
              isCTATitleShown={false}
              isDisabled={isDisabled}
              isListJourneyBenefitsLoading={isListJourneyBenefitsLoading}
              isListJourneyNewsLoading={isListJourneyNewsLoading}
              isMaterialsLoading={isMaterialsLoading}
              isSpecialLinksLoading={isSpecialLinksLoading}
              isSurveysLoading={isSurveysLoading}
              journeyBenefits={journeyBenefits}
              journeyNews={journeyNews}
              materials={materials}
              prompts={prompts}
              specialLinks={specialLinks}
              steps={steps}
              surveys={surveys}
              tools={tools}
            />
          </Grid>
          {isDeleteEnabled && (
            <Grid item>
              <IconButton
                disabled={isDisabled}
                onClick={onDropdownDataDelete(i + 1)}
              >
                <DeleteForeverIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
};

const Interaction = ({
  interaction,
  interactions,
  journeyId,
  locale,
  name,
  isNew,
  journey,
  ...props
}) => {
  const {
    triggers,
    updateJourneySemantic,
    materials,
    fetchMaterials,
    fetchJourneyBenefits,
    fetchJourneyNews,
    templateFamilies,
    teamsTemplateFamilies,
    fetchTemplateFamilies,
    fetchTeamsTemplateFamilies,
    fetchSemanticTemplates,
    fetchTeamsSemanticTemplates,
    fetchSemanticPreviewTemplates,
    fetchSpecialLinks,
    isListJourneyBenefitsLoading,
    isListJourneyNewsLoading,
    isMaterialsLoading,
    specialLinks,
    isSpecialLinksLoading,
    updateJourneyMaterial,
    deleteJourneySemantic,
    groups,
    updateJourneyGroup,
    updateSpecialLink,
    triggerConfigs,
    updateTriggerConfig,
    journeys,
    flowRelations,
    semantics,
    semanticTemplates,
    teamsSemanticTemplates,
    //semanticPreviewTemplates,
    isScheduledSlotsLoading,
    scheduledSlots,
    deleteFlowRelation,
    createFlowRelation,
    userStatePaths,
    isUserStatePathsLoading,
    fetchUserStatePaths,
    isSurveysLoading,
    surveys,
    journeyBenefits,
    journeyNews,
    user,
    tools,
    fetchTools,
    prompts,
    fetchPrompts,
    fetchAllSurveysForUser: fetchSurveys,
  } = useAppContext();
  const location = useLocation();
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [wizardVisible, setWizardVisible] = useState(false);
  const [aiResult, setAiResult] = useState(null);
  const [localViewType, setLocalViewType] = useLocalStorage(
    `viewType`,
    "client"
  );
  const { enqueueSnackbar } = useSnackbar();

  const updateAiServiceResult = (result) => {
    setAiResult(result);
  };
  const handleAiClick = () => {
    setWizardVisible(true);
  };

  const { flowRelationsByFlowId } = useMemo(
    () => getData({ flowRelations }),
    [flowRelations]
  );

  if (Array.isArray(interaction.dropdownData)) {
    interaction.dropdownData[0] = {
      ...interaction.dropdownData[0],
      label: interaction.dropdownData[0]?.label ?? "Placeholder",
      value: interaction.dropdownData[0]?.value ?? "Placeholder",
    };
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...interaction,
      afterCallDelay: interaction?.afterCallDelay ?? 60,
      callBehaviour: interaction?.callBehaviour,
      callRelatedCustomization:
        interaction?.callRelatedCustomization ||
        Boolean(interaction?.callBehaviour),
      templateFamilyId: interaction.templateFamilyId
        ? interaction.templateFamilyId
        : LEGACY_TEMPLATEFAMILY_ID,
      templateFonts: {
        ctafontfamily: interaction.templateFonts?.ctafontfamily ?? null,
        ctafontsize: interaction.templateFonts?.ctafontsize ?? null,
        textfontfamily: interaction.templateFonts?.textfontfamily ?? null,
        textfontsize: interaction.templateFonts?.textfontsize ?? null,
        titlefontfamily: interaction.templateFonts?.titlefontfamily ?? null,
        titlefontsize: interaction.templateFonts?.titlefontsize ?? null,
      },
      templateColors: {
        color1: interaction.templateColors?.color1 ?? null,
        color1cta: interaction.templateColors?.color1cta ?? null,
        color2: interaction.templateColors?.color2 ?? null,
        color2cta: interaction.templateColors?.color2cta ?? null,
        color3: interaction.templateColors?.color3 ?? null,
        color4: interaction.templateColors?.color4 ?? null,
      },

      semanticTemplateId: interaction.semanticTemplateId ?? null,
      semanticPreview: {
        semanticPreviewTemplateId:
          interaction.semanticPreview?.semanticPreviewTemplateId ||
          NO_PREVIEW_ID,
        cta1Text: interaction.semanticPreview?.cta1Text || "",
        text: draftToMarkdown(
          markdownToDraft(interaction.semanticPreview?.text || "")
        ),
        title: draftToMarkdown(
          markdownToDraft(interaction.semanticPreview?.title || "")
        ),
        ctafontfamily: interaction.semanticPreview?.ctafontfamily ?? null,
        ctafontsize: interaction.templateFonts?.ctafontsize ?? null,
        textfontfamily: interaction.semanticPreview?.textfontfamily ?? null,
        textfontsize: interaction.semanticPreview?.textfontsize ?? null,
        titlefontfamily: interaction.semanticPreview?.titlefontfamily ?? null,
        titlefontsize: interaction.semanticPreview?.titlefontsize ?? null,
      },
      title: draftToMarkdown(markdownToDraft(interaction.title || "")),
      text: draftToMarkdown(markdownToDraft(interaction.text || "")),
      viewType: interaction?.viewType
        ? interaction.viewType
        : interaction.isNew
        ? localViewType
        : "client",
      teams: {
        backgrColorImage: interaction.teams?.backgrColorImage ?? null,
        backgroundColor: interaction.teams?.backgroundColor ?? null,
        backgroundImage: interaction.teams?.backgroundImage ?? null,
        horizontalAlignment: interaction.teams?.horizontalAlignment ?? null,
        imageHeight: interaction.teams?.imageHeight ?? null,
        imageWidth: interaction.teams?.imageWidth ?? null,
        mainImage: interaction.teams?.mainImage ?? null,
        semanticTemplateId: interaction.teams?.semanticTemplateId
          ? interaction.teams.semanticTemplateId
          : "crd-1c51bc52-ea39-41cf-beba-3dfb44f84744",
        size: interaction.teams?.size ?? null,
        templateFamilyId: interaction?.teams?.templateFamilyId
          ? interaction.teams.templateFamilyId
          : "fam-038da0d7-6481-4368-b1f4-a692e34929d2",
        textColor: interaction.teams?.textColor ?? "Default",
        textSize: interaction.teams?.textSize ?? "Default",
        titleColor: interaction.teams?.titleColor ?? "Default",
        titleSize: interaction.teams?.titleSize ?? "Default",
      },
      tipgroup: interaction?.tipgroup ?? null,
      dropdownData: interaction.dropdownData || [
        {
          id: "placeholder",
          label: "Placeholder",
          value: "placeholder",
        },
        {
          id: "option1",
          label: "Option 1",
          value: "option1",
        },
      ],
      relation: flowRelationsByFlowId[interaction.flowId]?.[0] ?? null,
    },
    validationSchema: validationSchema(interaction, journey?.days?.length),
    onSubmit: async ({ relation, ...values }, { resetForm }) => {
      if (values.tipgroup === null) {
        delete values.tipgroup;
      }
      if (values.callRelatedCustomization === false) {
        values.callBehaviour = null;
        values.afterCallDelay = null;
      }
      // For Teams View
      if (values.viewType === "teams_app") {
        if (values.teams?.backgroundColor && !values?.teams?.backgroundImage) {
          const imageUrl = generateImage(values.teams.backgroundColor);
          values.teams.backgrColorImage = imageUrl;
        }
        if (!values.teams?.backgroundColor) {
          values.teams.backgrColorImage = null;
        }
        if (values.teams.mainImage) {
          const { width, height } = await fetchImageDimensions();
          values.teams.imageWidth = width;
          values.teams.imageHeight = height;
        } else {
          values.teams.imageWidth = null;
          values.teams.imageHeight = null;
        }
      }
      // ==== START TODO: improve this; use yup etc.
      if (values.templateColors) {
        values.templateColors = removeEmpty(values.templateColors);
        const reg = /^#([0-9a-f]{3}){1,2}$/i; // hex colors
        Object.keys(values.templateColors).forEach((k) => {
          if (values.templateColors[k] && !reg.test(values.templateColors[k])) {
            window.alert(`Invalid Hex Color code ${k}; please correct asap`);
          }
        });
        // overwrite with color1 and color2, in case empty; feature removed in task-variousimprovements..
        // if (!values.templateColors?.color2cta && values.templateColors.color1) {
        //   values.templateColors.color2cta = values.templateColors.color1;
        // }
        // if (!values.templateColors?.color1cta && values.templateColors.color2) {
        //   values.templateColors.color1cta = values.templateColors.color2;
        // }
      }

      // If "#" and nothing else, transparent color
      if (values.templateColors.color4 === "#")
        values.templateColors.color4 = "#00000000";

      // If empty, use the default color (commented here, because we rely on the templating engine applying the default color)
      // if (values.templateColors.color4 === undefined)
      //   values.templateColors.color4 = "#B9B9B9";

      if (values.ctaType !== "DROPDOWN" && values.userStatePath) {
        // so that it will be updated in dynamo;
        // if you just remove that field, the update query won't be built with that field
        // if you set undefined, JSON.stringify will strip that field away
        values.userStatePath = null;
        // Note: if you want to store data also with button click (not dropdown):
        // add cta1Value and/or cta2Value and userStatePath and change this part to avoid setting userStatePath to null in those cases
      }

      if (values.templateFonts) {
        values.templateFonts = removeEmpty(values.templateFonts);
        const reg = /[0-9]*px/i; // font-size values
        Object.keys(values.templateFonts)
          .filter((el) => el.endsWith("fontsize"))
          .forEach((k) => {
            if (values.templateFonts[k] && !reg.test(values.templateFonts[k])) {
              window.alert(
                `Invalid font size value ${k}; please correct asap (use px) `
              );
            }
          });
      }

      if (values.semanticPreview) {
        values.semanticPreview = removeEmpty(values.semanticPreview);
        const reg = /[0-9]*px/i; // font-size values
        Object.keys(values.semanticPreview)
          .filter((el) => el.endsWith("fontsize"))
          .forEach((k) => {
            if (
              values.semanticPreview[k] &&
              !reg.test(values.semanticPreview[k])
            ) {
              window.alert(
                `Invalid font size value ${k}; please correct asap (use px) `
              );
            }
          });
      }
      // ==== END TODO: improve this; use yup etc.

      if (values.isNew && values.type === "NUDGE") {
        // IMPORTANT NOTE: if you change defaults here, change also in copySemanticFlowLambda
        if (
          !triggerConfigs.find(
            ({ stimulusId }) => stimulusId === formik.values.stimulusId
          )
        ) {
          await updateTriggerConfig({
            journeyId,
            stimulusId: formik.values.stimulusId,
            tiers: [
              {
                from: 1,
                maxDailyOccurrences: 1,
                minOccurrencesInterval: 30,
                maxMonthlyOccurrences: undefined,
                maxWeeklyOccurrences: undefined,
                extendGlobalRulesToTips: false,
                dontShowNudges: false,
              },
            ],
            postJourneyTier: {
              maxDailyOccurrences: 6,
              minOccurrencesInterval: 60,
              maxMonthlyOccurrences: undefined,
              maxWeeklyOccurrences: undefined,
              extendGlobalRulesToTips: false,
              dontShowNudges: true,
            },
          });
        }

        const firstGroup = groups[values.stimulusId]?.[0];
        const group = firstGroup
          ? {
              flows: [
                { id: values.flowId, isPublished: true },
                ...firstGroup.flows,
              ],
              id: firstGroup.id,
            }
          : {
              flows: [{ id: values.flowId, isPublished: true }],
              inPastDaysNumber: 1,
              isNew: true,
              isTakeAll: true,
              journeyId,
              order: 1,
              stimulusId: values.stimulusId,
            };

        await updateJourneyGroup(group);
      }

      const oldRelation = flowRelationsByFlowId[values.flowId]?.[0];
      if (relation?.relationId !== oldRelation?.relationId) {
        if (oldRelation) {
          await deleteFlowRelation(oldRelation);
        }

        if (relation) {
          await createFlowRelation({
            ...relation,
            flowId: values.flowId,
            journeyId,
          });
        }
      }

      await updateJourneySemantic(values, { optimisticUpdates: false });
      await resetForm();

      if (values.isNew) {
        history.replace({ ...location, state: null });
      }
    },
    validator: () => ({}),
  });

  const calculateImageDimensions = (
    width,
    height,
    originalWidth,
    originalHeight
  ) => {
    const numericWidthValue = width ? parseInt(width.replace("px", "")) : null;
    const numericHeightValue = height
      ? parseInt(height.replace("px", ""))
      : null;
    const aspectRatio = originalWidth / originalHeight;

    if (numericWidthValue < originalWidth) {
      /*
      formik.setFieldValue("teams.horizontalAlignment", "center")
      formik.setFieldTouched("teams.horizontalAlignment", true);
      formik.setFieldValue("teams.size", null)
      formik.setFieldTouched("teams.size", true);
      */
      formik.values.teams.horizontalAlignment = "center";
      formik.values.teams.size = null;
    } else if (numericWidthValue > originalWidth) {
      formik.values.teams.horizontalAlignment = null;
      formik.values.teams.size = "stretch";
    }

    const pattern = /^\d+px$/;

    if ((width && !pattern.test(width)) || (height && !pattern.test(height))) {
      enqueueSnackbar("Invalid Image Size Input", {
        variant: "error",
      });
    }
    if (numericWidthValue && !numericHeightValue && !isNaN(aspectRatio)) {
      return {
        height: `${Math.round(numericWidthValue / aspectRatio)}px`,
        width: `${numericWidthValue}px`,
      };
    } else if (
      !numericWidthValue &&
      numericHeightValue &&
      !isNaN(aspectRatio)
    ) {
      return {
        height: `${numericHeightValue}px`,
        width: `${Math.round(numericHeightValue * aspectRatio)}px`,
      };
    } else if (numericWidthValue && numericHeightValue) {
      return {
        height: `${numericHeightValue}px`,
        width: `${numericWidthValue}px`,
      };
    } else {
      return {
        height: "180px",
        width: `${Math.round(180 * aspectRatio)}px`,
      };
    }
  };

  const fetchImageDimensions = async () => {
    let imageUrl;
    if (formik.values.teams.mainImage) {
      imageUrl = formik.values.teams.mainImage;

      try {
        const img = new Image();
        img.src = imageUrl;
        await img.decode();

        const { width, height } = calculateImageDimensions(
          formik.values.teams.imageWidth,
          formik.values.teams.imageHeight,
          img.width,
          img.height
        );
        return { height, width };
      } catch (error) {
        console.error("Error fetching image dimensions:", error);
        return { height: null, width: null };
      }
    }
  };

  const generateImage = (color) => {
    // fillMode to cover
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    canvas.width = 10;
    canvas.height = 10;

    context.fillStyle = color;
    context.fillRect(0, 0, canvas.width, canvas.height);

    const imageURL = canvas.toDataURL();
    return imageURL;

    //console.log('Generated Image URL:', imageURL);
  };

  useEffect(() => {
    const newValue = formik.values.viewType;
    setLocalViewType(newValue);
  }, [formik.values.viewType, setLocalViewType]);

  useEffect(() => {
    const newValue = formik.values.templateFamilyId;
    setSelectedTemplate(newValue);
  }, [formik.values.templateFamilyId]);

  useEffect(() => {
    const newValue = formik.values.templateFamilyId;
    setSelectedTemplate(newValue);
  }, [formik.values.templateFamilyId]);

  useEffect(() => {
    const defaultDropdownData = [
      {
        id: "placeholder",
        label: "Placeholder",
        value: "placeholder",
      },
      {
        id: "option1",
        label: "Option 1",
        value: "option1",
      },
    ];
    if (aiResult) {
      // This approach only works by deleting the values set for text and title
      // However does not work as expected and causes an issue while typing on the markdown editor

      /* formik.setFieldValue("title", aiResult.title);
      formik.setFieldTouched("title", true);
      formik.setFieldValue("text",   aiResult.text);
      formik.setFieldTouched("text", true); */

      if (aiResult.outputType !== "dropdown") {
        formik.setFieldValue("dropdownData", defaultDropdownData);
        formik.setFieldTouched("dropdownData", true);
      }
      if (aiResult.outputType === "dropdown") {
        const dropdownData = [
          {
            id: "label",
            label: aiResult.placeholder,
            value: aiResult.placeholder,
          },

          ...aiResult.answers.map((answer, index) => ({
            id: `option${index + 1}`,
            label: answer || `Option ${index + 1}`,
            value: index + 1 || `option${index + 1}`,
          })),
        ];
        formik.setFieldValue("cta1Text", aiResult.button);
        formik.setFieldTouched("cta1Text", true);
        formik.setFieldValue("dropdownData", dropdownData);
        formik.setFieldTouched("dropdownData", true);
        formik.setFieldValue("ctaType", "DROPDOWN");
        formik.setFieldTouched("ctaType", true);
      } else if (aiResult.outputType === "singleCTA") {
        formik.setFieldValue("cta1Text", aiResult.answers[0]);
        formik.setFieldTouched("cta1Text", true);
        formik.setFieldValue("ctaType", "CTA");
        formik.setFieldTouched("ctaType", true);
      } else if (aiResult.outputType === "doubleCTA") {
        formik.setFieldValue("cta1Text", aiResult.answers[0]);
        formik.setFieldTouched("cta1Text", true);
        formik.setFieldValue("cta2Text", aiResult.answers[1]);
        formik.setFieldTouched("cta2Text", true);
        formik.setFieldValue("ctaType", "DOUBLE_CTA");
        formik.setFieldTouched("ctaType", true);
      }
    }
  }, [aiResult]);

  const interactionIndex = useMemo(
    () => interactions.findIndex((item) => item.id === interaction.id),
    [interactions, interaction]
  );

  const trigger = useMemo(
    () =>
      triggers.find(({ stimulusId }) => stimulusId === interaction.stimulusId),
    [interaction.stimulusId, triggers]
  );

  const contentTypeConfig = useMemo(
    () => trigger && contentTypesConfig[trigger.contentType],
    [trigger]
  );

  const handleTypeChange = useCallback(
    (e, newType) => {
      if (!newType) {
        return;
      }
      formik.setFieldValue("ctaType", newType);
      formik.setFieldTouched("ctaType", true);
    },
    [formik]
  );

  const isDisabled = props.isDisabled || formik.isSubmitting;

  useEffect(() => {
    if (
      [
        formik.values.cta1Type,
        formik.values.cta2Type,
        ...formik.values.dropdownData.map(({ ctaType }) => ctaType),
      ].some((ctaType) => ctaType === "Dashboard Material")
    ) {
      fetchMaterials({ journeyId, locale });
    }

    if (
      [
        formik.values.cta1Type,
        formik.values.cta2Type,
        ...formik.values.dropdownData.map(({ ctaType }) => ctaType),
      ].some((ctaType) => ctaType === "Special Link")
    ) {
      fetchSpecialLinks();
    }

    if (
      !surveys.length &&
      (formik.values?.cta1Type === "Survey" ||
        formik.values?.cta2Type === "Survey" ||
        (formik.values.ctaType === "DROPDOWN" &&
          formik.values.dropdownData.length &&
          formik.values.dropdownData.some(
            ({ ctaType }) => ctaType === "Survey"
          )))
    ) {
      fetchSurveys({ journeyId });
    }

    if (
      !tools.length &&
      (formik.values?.cta1Type === "Prompt Tool" ||
        formik.values?.cta2Type === "Prompt Tool")
    ) {
      fetchTools();
    }
    if (
      !prompts.length &&
      (formik.values?.cta1Type === "Prompt" ||
        formik.values?.cta2Type === "Prompt")
    ) {
      fetchPrompts();
    }
    if (
      [
        formik.values.cta1Type,
        formik.values.cta2Type,
        ...formik.values.dropdownData.map(({ ctaType }) => ctaType),
      ].some((ctaType) => ctaType === "News")
    ) {
      fetchJourneyNews({ journey_id: journeyId });
    }
    if (
      [
        formik.values.cta1Type,
        formik.values.cta2Type,
        ...formik.values.dropdownData.map(({ ctaType }) => ctaType),
      ].some((ctaType) => ctaType === "Welfare")
    ) {
      fetchJourneyBenefits({ journey_id: journeyId });
    }
  }, [
    journeyId,
    formik.values.dropdownData,
    formik.values.cta1Type,
    formik.values.cta2Type,
    locale,
  ]);

  useEffect(() => {
    if (formik.values.ctaType !== "DROPDOWN") {
      return;
    }

    fetchUserStatePaths();
  }, [formik.values.ctaType]);

  const semanticTemplateCatalogue = useMemo(() => {
    return (
      templateFamilies.filter(
        (f) => f.tFamilyId === formik.values.templateFamilyId
      )[0]?.semanticTemplateCatalogue ?? []
    );
  }, [templateFamilies, formik.values.templateFamilyId]);

  const teamsSemanticTemplateCatalogue = useMemo(() => {
    return (
      teamsTemplateFamilies.filter(
        (f) => f.tFamilyId === formik.values.teams.templateFamilyId
      )[0]?.semanticTemplateCatalogue ?? []
    );
  }, [teamsTemplateFamilies, formik.values.teams.templateFamilyId]);

  //Feature removed in task Simplify UI of tip creation in content portal (24-01-2024)
  // const isSemanticPreviewFieldDisabled = useCallback(
  //   (fieldName) => {
  //     return (
  //       (semanticTemplateCatalogue && semanticTemplateCatalogue?.length) <= 0 ||
  //       !formik.values?.semanticPreview?.semanticPreviewTemplateId ||
  //       formik.values.semanticPreview.semanticPreviewTemplateId ===
  //         NO_PREVIEW_ID ||
  //       !semanticPreviewTemplates?.[0]?.allowedCustomization?.[fieldName]
  //     );
  //   },
  //   [
  //     semanticTemplateCatalogue,
  //     formik.values.semanticPreview.semanticPreviewTemplateId,
  //     semanticPreviewTemplates,
  //   ]
  // );

  // Feature removed in task Simplify UI of tip creation in content portal (24-01-2024)
  // const semanticPreviewTemplateCatalogue = useMemo(() => {
  //   const arr =
  //     semanticTemplates.filter(
  //       (t) => t.templateId === formik.values.semanticTemplateId
  //     )[0]?.semanticPreviewTemplateCatalogue ?? [];

  //   return arr;
  // }, [semanticTemplates, formik.values.semanticTemplateId]);

  const handleDropdownDataChange = useCallback(
    (i, name) => (e) => {
      const newValue = [...(formik.values.dropdownData || [])];
      newValue[i] = {
        id: i === 0 ? "placeholder" : `option_${i}`,
        label: "",
        value: "",
        ...newValue[i],
        [name]: (typeof e === "string" ? e : e?.target?.value) || "",
      };

      formik.setFieldValue("dropdownData", newValue);
      formik.setFieldTouched("dropdownData", true);
    },
    [formik]
  );

  const handleDropdownDataDelete = useCallback(
    (i) => () => {
      const newValue = [...formik.values.dropdownData];
      newValue.splice(i, 1);

      formik.setFieldValue("dropdownData", newValue);
      formik.setFieldTouched("dropdownData", true);
    },
    [formik]
  );

  const history = useHistory();

  useConfirmLeave();

  //setting property IMAGE IMAGE
  const handleImageChangeImageID = useCallback(
    ({ url }) => {
      formik.setFieldValue("imageId", url);
      formik.setFieldTouched("imageId", true);
    },
    [formik]
  );

  const handleImageChangeMainImage = useCallback(
    ({ url }) => {
      formik.setFieldValue("teams.mainImage", url);
      formik.setFieldTouched("teams.mainImage", true);
    },
    [formik]
  );

  const handleImageChangeBG1 = useCallback(
    ({ url }) => {
      formik.setFieldValue(`images.bg1`, url);
      formik.setFieldTouched(`images.bg1`, true);
    },
    [formik]
  );

  const handleImageChangeBG2 = useCallback(
    ({ url }) => {
      formik.setFieldValue(`images.bg2`, url);
      formik.setFieldTouched(`images.bg2`, true);
    },
    [formik]
  );

  const handleImageChangeBackgroundImage = useCallback(
    ({ url }) => {
      formik.setFieldValue(`teams.backgroundImage`, url);
      formik.setFieldTouched(`teams.backgroundImage`, true);
    },
    [formik]
  );

  const handleImageResetImageID = useCallback(() => {
    formik.setFieldValue("imageId", "");
    formik.setFieldTouched("imageId", true);
  }, [formik]);

  const handleImageResetMainImage = useCallback(() => {
    formik.setFieldValue("teams.mainImage", "");
    formik.setFieldTouched("teams.mainImage", true);
  }, [formik]);

  const handleImageResetBG1 = useCallback(() => {
    formik.setFieldValue(`images.bg1`, "");
    formik.setFieldTouched(`images.bg1`, true);
  }, [formik]);

  const handleImageResetBG2 = useCallback(() => {
    formik.setFieldValue(`images.bg2`, "");
    formik.setFieldTouched(`images.bg2`, true);
  }, [formik]);

  const handleImageResetBackgroundImage = useCallback(() => {
    formik.setFieldValue(`teams.backgroundImage`, "");
    formik.setFieldTouched(`teams.backgroundImage`, true);
  }, [formik]);

  const title = interaction.type === "NUDGE" ? "Nudge" : "Tip";
  const titleLowerCased = title.toLowerCase();

  const modals = useModals();

  const handleInteractionClick = useCallback(
    (e, semanticId) => {
      if (!semanticId) {
        return;
      }

      if (!formik.dirty) {
        history.replace(
          `/journeys/${journeyId}/${locale}/${interaction.type.toLowerCase()}s/${semanticId}`
        );
        return;
      }

      modals.openConfirmation({
        closeCTATitle: "Cancel",
        confirmCTATitle: "Yes",
        onConfirm: () => {
          history.replace(
            `/journeys/${journeyId}/${locale}/${interaction.type.toLowerCase()}s/${semanticId}`
          );
        },
        text: `You have unsaved changes. Are you sure you want to proceed?`,
        title: "Go to another interaction?",
      });
    },
    [formik.dirty, history, interaction.type, journeyId, locale, modals]
  );

  const handleCreateMaterial = useCallback(
    (column) => (e) => {
      e.preventDefault();
      const newMaterial = {
        id: `sem-${uuidv4()}`,
        isNew: true,
        order: 0,
      };
      modals.openModal(modals.modalTypes.EditMaterial, {
        journey,
        locale,
        material: newMaterial,
        journeys,
        onClose: async (value) => {
          if (value) {
            return;
          }
          await formik.setFieldValue(column, formik.values[column]);
          await formik.setFieldTouched(column);
        },
        onConfirm: async (material) => {
          await updateJourneyMaterial({
            ...newMaterial,
            ...material,
            journeyId,
          });
          await formik.setFieldValue(column, newMaterial.id);
          await formik.setFieldTouched(column);
        },
      });
    },
    [
      modals,
      journey,
      locale,
      journeys,
      formik,
      updateJourneyMaterial,
      journeyId,
    ]
  );

  const handleCreateSpecialLink = useCallback(
    (column) => (e) => {
      e.preventDefault();
      modals.openModal(modals.modalTypes.EditSpecialLink, {
        isNew: true,
        onClose: async (value) => {
          if (value) {
            return;
          }

          await formik.setFieldValue(column, formik.values[column]);
          await formik.setFieldTouched(column);
        },
        onConfirm: async (specialLink) => {
          const newSpecialLink = await updateSpecialLink({
            isNew: true,
            ...specialLink,
          });
          await formik.setFieldValue(column, newSpecialLink.id);
          await formik.setFieldTouched(column);
        },
      });
    },
    [formik, modals, updateSpecialLink]
  );

  const handleInteractionDelete = useCallback(() => {
    modals.openConfirmation({
      closeCTATitle: "Cancel",
      confirmCTATitle: "Delete",
      onConfirm: async () => {
        const index = interactions.findIndex(
          (item) => item.id === interaction.id
        );
        await deleteJourneySemantic({ id: interaction.id, journeyId });

        if (index === 0) {
          history.replace(
            `/journeys/${journeyId}/${locale}/${
              interaction.type === "NUDGE" ? "contextual" : "tips"
            }`
          );
          return;
        }

        history.replace(
          `/journeys/${journeyId}/${locale}/${interaction.type.toLowerCase()}s/${
            interactions[index - 1].id
          }`
        );
      },
      text: `You are about to permanently delete tip ${
        interactions.length > 1 ? interactionIndex + 1 : ""
      } from this flow.`,
      title: "Delete the tip?",
    });
  }, [
    deleteJourneySemantic,
    history,
    interaction.id,
    interaction.type,
    interactionIndex,
    interactions,
    journeyId,
    locale,
    modals,
  ]);

  const handleAdd = useCallback(
    async (newSemantic) => {
      if (
        trigger?.contentType === "onboarding" ||
        trigger?.contentType === "step_guide"
      ) {
        await updateJourneySemantic(
          {
            cta2Target: newSemantic.id,
            cta2Text: "Next ➡",
            cta2Type: "Semantic",
            id: interactions[interactions.length - 1].id,
            journeyId,
          },
          { optimisticUpdates: false }
        );
      }
      await updateJourneySemantic(newSemantic, {
        optimisticUpdates: false,
      });

      history.push(`/journeys/${journeyId}/${locale}/nudges/${newSemantic.id}`);
    },
    [
      history,
      interactions,
      journeyId,
      locale,
      trigger?.contentType,
      updateJourneySemantic,
    ]
  );

  const {
    isLoading: isAddingNewInteraction,
    handleConfirm: handleAddInteraction,
  } = useModal({
    onConfirm: handleAdd,
  });

  const handleAddNewInteraction = useCallback(async () => {
    if (interaction.type === "PRIME") {
      const id = `sem-${uuidv4()}`;
      history.push(`/journeys/${journeyId}/${locale}/primes/${id}`, {
        flowId: interaction.flowId,
        isNew: true,
        type: "PRIME",
      });
      return;
    }

    const id = `sem-${uuidv4()}`;
    const params = {
      cta1Target: interactions[interactions.length - 1].id,
      cta1Text: "⬅ Back",
      cta1Type: "Semantic",
      cta2Text: "Finish",
      cta2Type: "Close",
      ctaType: "DOUBLE_CTA",
      flowId: interaction.flowId,
      id,
      index: interactions.length,
      isFirstInFlow: false,
      isNew: true,
      journeyId,
      semanticId: id,
      showX: true,
      text: `Deep dive into the tip's topic and showcase **the value ${
        interactions.length + 1
      } for the user.**`,
      type: interaction.type,
      ...(interaction.stimulusId && {
        stimulusId: interaction.stimulusId,
      }),
    };

    if (!formik.dirty) {
      await handleAddInteraction(params);
      return;
    }

    modals.openConfirmation({
      closeCTATitle: "Cancel",
      confirmCTATitle: "Yes",
      onConfirm: () => handleAddInteraction(params),
      text: `You have unsaved changes. Are you sure you want to proceed?`,
      title: "Add new interaction?",
    });
  }, [
    interaction.type,
    interaction.flowId,
    interaction.stimulusId,
    interactions,
    journeyId,
    formik.dirty,
    modals,
    history,
    locale,
    handleAddInteraction,
  ]);

  const theme = useTheme();

  const { containerRef: previewContainerRef, height } = useStickyHeight();

  const { flowRelationsByRelationId } = useMemo(
    () => getData({ semantics, flowRelations, days: journey?.days }),
    [semantics, flowRelations, journey?.days]
  );

  const handleAssociationChange = useCallback(
    (e, value, reason) => {
      if (!["clear", "select-option"].includes(reason)) {
        return;
      }

      formik.setFieldValue(
        "relation",
        value
          ? {
              relationId: value.id,
              type: value.groupColumn === "Days" ? "day" : "scheduledSlot",
            }
          : null
      );
      formik.setFieldTouched("relation", true);
    },
    [formik]
  );

  const relationsOptions = useMemo(
    () => [
      ...(journey?.days ?? [])
        .filter(
          ({ id: dayId }) =>
            !flowRelationsByRelationId[dayId] ||
            formik.values.relation?.relationId === dayId
        )
        .map((day) => ({
          ...day,
          groupColumn: "Days",
        })),

      ...scheduledSlots
        .filter(
          ({ id: scheduledSlotId }) =>
            !flowRelationsByRelationId[scheduledSlotId] ||
            formik.values.relation?.relationId === scheduledSlotId
        )
        .map((scheduledSlot) => ({
          ...scheduledSlot,
          groupColumn: "Scheduled Slots",
        })),
    ],
    [
      journey?.days,
      scheduledSlots,
      flowRelationsByRelationId,
      formik.values.relation?.relationId,
    ]
  );

  useEffect(() => {
    if (
      (formik.values.viewType === "client" && !templateFamilies) ||
      !templateFamilies.length
    ) {
      fetchTemplateFamilies();
    }
    if (
      (formik.values.viewType === "teams_app" && !teamsTemplateFamilies) ||
      !teamsTemplateFamilies.length
    ) {
      fetchTeamsTemplateFamilies();
    }
  }, [
    formik.values.viewType,
    fetchTeamsTemplateFamilies,
    fetchTemplateFamilies,
    teamsTemplateFamilies,
    templateFamilies,
  ]);

  useEffect(() => {
    // Fetching semanticTemplate
    if (
      formik.values.viewType === "client" &&
      formik.values?.semanticTemplateId &&
      semanticTemplates?.[0]?.templateId !== formik.values.semanticTemplateId
    ) {
      fetchSemanticTemplates({
        semanticTemplateId: formik.values.semanticTemplateId,
      });
    }
    if (
      formik.values.viewType === "teams_app" &&
      formik.values?.teams?.semanticTemplateId &&
      teamsSemanticTemplates?.[0]?.templateId !==
        formik.values.teams.semanticTemplateId
    ) {
      fetchTeamsSemanticTemplates({
        teamsSemanticTemplateId: formik.values.teams.semanticTemplateId,
      });
    }
  }, [
    fetchSemanticTemplates,
    fetchTeamsSemanticTemplates,
    formik.values.semanticTemplateId,
    formik.values.teams.semanticTemplateId,
    formik.values.viewType,
    semanticTemplates,
    teamsSemanticTemplates,
  ]);

  useEffect(() => {
    if (formik.values.semanticPreview.semanticPreviewTemplateId) {
      fetchSemanticPreviewTemplates({
        semanticPreviewTemplateId:
          formik.values.semanticPreview.semanticPreviewTemplateId,
      });
      // clean-up all the related fields
      formik.values.semanticPreview.title = "";
      // interaction.semanticPreview.title = "";
      formik.values.semanticPreview.text = "";
      // interaction.semanticPreview.text = "";
      formik.values.semanticPreview.cta1Text = "";
    }
  }, [
    fetchSemanticPreviewTemplates,
    formik.values.semanticPreview.semanticPreviewTemplateId,
  ]);
  /*  const tipGroups = [
    {
        id:"123",
        name: "test1"
    },
    {
      id:"321",
      name:"test2"
    },
] */
  const { fetchTipGroups, tipGroups } = useAppContext();

  useEffect(() => {
    fetchTipGroups();
  }, []);

  return (
    <div>
      {wizardVisible ? (
        <AiIntegration
          aiResult={updateAiServiceResult}
          journeyId={journey.id}
          journeyLanguage={journey.locale}
          setWizardVisible={setWizardVisible}
        />
      ) : (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid container item>
                <Timestamps
                  copiedFromEntity={interaction.type.toLowerCase()}
                  copiedFromEntityId={interaction.copiedFromSemanticId}
                  copiedFromJourneyId={interaction.copiedFromJourneyId}
                  createdAt={interaction.createdAt}
                  createdBy={interaction.createdBy}
                  locale={locale}
                  updatedAt={interaction.updatedAt}
                  updatedBy={interaction.updatedBy}
                />
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      select
                      label="View Type"
                      {...getFieldProps(formik, {
                        defaultValue: formik.values.viewType || "client",
                        name: "viewType",
                        required: true,
                      })}
                      variant="outlined"
                    >
                      <MenuItem disabled value="">
                        Select View Type
                      </MenuItem>
                      <MenuItem value="client">Client View</MenuItem>
                      <MenuItem value="teams_app">Teams View</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={4}>
                    {interaction.type === "NUDGE" && (
                      <Typography variant="h6">{trigger?.name}</Typography>
                    )}
                    {interaction.type === "PRIME" && journey && (
                      <Autocomplete
                        fullWidth
                        filterOptions={filterOptions}
                        getOptionLabel={(option) =>
                          option.groupColumn === "Days"
                            ? `Day ${option.day} - ${option.name}`
                            : getScheduledSlotName({
                                datePeriod: option.datePeriod,
                                datetimeEnd: option.datetimeEnd,
                                datetimeStart: option.datetimeStart,
                                specificInterval: option?.specificInterval,
                              })
                        }
                        groupBy={(option) => option.groupColumn}
                        loading={isScheduledSlotsLoading}
                        options={relationsOptions}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                              ...params.InputProps,
                            }}
                            label="Associated to"
                            placeholder="Unassociated"
                            // style={{ maxWidth: 300 }}
                            variant="outlined"
                          />
                        )}
                        renderOption={(option, { inputValue }) => (
                          <ListItemText
                            primary={
                              option.groupColumn === "Days" ? (
                                <Highlighter
                                  searchWords={[inputValue || ""]}
                                  textToHighlight={option.name}
                                />
                              ) : (
                                // in case of scheduled slots
                                getScheduledSlotDatetimeIntervalString({
                                  datetimeEnd: option.datetimeEnd,
                                  datetimeStart: option.datetimeStart,
                                })
                              )
                            }
                            secondary={
                              option.groupColumn === "Days"
                                ? `Day ${option.day}`
                                : getScheduledSlotSpecificIntervalString({
                                    datePeriod: option.datePeriod,
                                    specificInterval: option.specificInterval,
                                  })
                            }
                          />
                        )}
                        value={relationsOptions.find(
                          ({ id }) => id === formik.values.relation?.relationId
                        )}
                        onChange={handleAssociationChange}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>

              {(user.groups.includes("Admins") ||
                !user.companies?.includes(
                  "5706867a-c563-4ca5-b803-c1883c38b640"
                )) && (
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        select
                        label="Tip Group for analytics"
                        {...getFieldProps(formik, {
                          defaultValue: formik.values.tipgroup || "",
                          name: "tipgroup",
                          required: true,
                        })}
                        variant="outlined"
                      >
                        <MenuItem disabled value="">
                          Select Tip Group
                        </MenuItem>
                        {tipGroups.map((tipGroup, index) => (
                          <MenuItem key={index} value={tipGroup.id}>
                            {tipGroup.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={8}>
              <Grid item xs={8}>
                <Grid container spacing={4}>
                  <Grid item style={{ paddingBottom: "0px" }} xs={12}>
                    <Grid container justify="space-between" spacing={2}>
                      <Grid item style={{ maxWidth: "100%" }}>
                        <Box
                          display="flex"
                          maxWidth="100%"
                          style={{ overflowX: "auto" }}
                        >
                          <ToggleButtonGroup
                            exclusive
                            size="small"
                            value={interaction.id}
                            onChange={handleInteractionClick}
                          >
                            {interactions.map(
                              ({ id, title, text, isFirstInFlow }, i) => (
                                <Tooltip
                                  key={id}
                                  title={
                                    <>
                                      {title && <b>{title}</b>}
                                      {text && <br />}
                                      {text && <br />}
                                      <i>{text}</i>
                                    </>
                                  }
                                >
                                  <ToggleButton
                                    selected={interaction.id === id}
                                    style={{
                                      ...(interaction.id === id && {
                                        borderColor: theme.palette.primary.main,
                                        borderWidth: 2,
                                      }),
                                    }}
                                    value={id}
                                  >
                                    {interaction.type === "PRIME" && "TIP "}
                                    {i + 1}
                                    {isFirstInFlow && <> (first of the flow)</>}
                                  </ToggleButton>
                                </Tooltip>
                              )
                            )}
                          </ToggleButtonGroup>
                        </Box>
                      </Grid>
                      {trigger?.contentType !== "feature_promotion" &&
                        !isNew && (
                          <Grid item>
                            <Grid container spacing={1}>
                              {journey &&
                                (interaction.type === "PRIME" ||
                                  !trigger?.contentType ||
                                  (!["onboarding", "step_guide"].includes(
                                    trigger?.contentType
                                  ) &&
                                    interactionIndex > 1 &&
                                    interactionIndex + 1 ===
                                      interactions.length)) && (
                                  <Grid item>
                                    <Tooltip
                                      interactive={interaction.isFirstInFlow}
                                      title={
                                        interaction.isFirstInFlow
                                          ? "First tip cannot be deleted. Mark another tip as first and then you can delete this tip."
                                          : null
                                      }
                                    >
                                      <span>
                                        <OutlinedButton
                                          disabled={
                                            isDisabled ||
                                            interaction.isFirstInFlow
                                          }
                                          onClick={handleInteractionDelete}
                                        >
                                          Delete{" "}
                                          {interaction.type === "PRIME" &&
                                            "TIP"}
                                        </OutlinedButton>
                                      </span>
                                    </Tooltip>
                                  </Grid>
                                )}
                              <Grid item>
                                <OutlinedButton
                                  disabled={
                                    isDisabled || isAddingNewInteraction
                                  }
                                  endIcon={
                                    isAddingNewInteraction && (
                                      <CircularProgress size={25} />
                                    )
                                  }
                                  startIcon={<AddIcon />}
                                  onClick={handleAddNewInteraction}
                                >
                                  Add new{" "}
                                  {interaction.type === "PRIME" && "TIP"}
                                </OutlinedButton>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                    </Grid>
                  </Grid>
                  {formik.values.type === "NUDGE" &&
                    formik.values.isFirstInFlow && (
                      <Grid item xs={12}>
                        <SpecialConditions
                          days={journey?.days?.length}
                          formik={formik}
                          isDisabled={isDisabled}
                          journeyId={journeyId}
                          journeys={journeys}
                          userStatePaths={userStatePaths}
                          {...(formik.values.type === "PRIME" && {
                            defaultType: "activeDays",
                            types: ["activeDays"],
                          })}
                          surveys={surveys}
                        />
                      </Grid>
                    )}
                  {![formik.values, ...interactions].some(
                    ({ conditions, isFirstInFlow }) =>
                      isFirstInFlow && conditions?.length
                  ) && (
                    <Grid item style={{ paddingTop: "0px" }} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            {...getFieldProps(formik, {
                              disabled: interaction.isFirstInFlow,
                              name: "isFirstInFlow",
                            })}
                            checked={
                              getFieldProps(formik, { name: "isFirstInFlow" })
                                .value
                            }
                          />
                        }
                        label={`First ${titleLowerCased} of the flow. Check here if this ${titleLowerCased} will be the first ${titleLowerCased} in the ${titleLowerCased} flow`}
                      />
                    </Grid>
                  )}
                  {formik.values.viewType === "client" && (
                    <>
                      {getFieldProps(formik, { name: "isFirstInFlow" })
                        .value === true && (
                        <Grid item style={{ paddingBottom: "0px" }} xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="primary"
                                {...getFieldProps(formik, {
                                  defaultValue: false,
                                  disabled:
                                    isDisabled ||
                                    !getFieldProps(formik, {
                                      name: "isFirstInFlow",
                                    }).value,
                                  name: "callRelatedCustomization",
                                })}
                                checked={
                                  getFieldProps(formik, {
                                    name: "callRelatedCustomization",
                                  }).value
                                }
                              />
                            }
                            label={`Customized message sending if the user in in a call`}
                          />
                        </Grid>
                      )}

                      {getFieldProps(formik, { name: "isFirstInFlow" })
                        .value === true &&
                        !getFieldProps(formik, {
                          name: "callRelatedCustomization",
                        }).value &&
                        journey?.[`callBehaviour${title}s`] && (
                          <Grid item style={{ paddingTop: "0px" }} xs={12}>
                            <Alert severity="warning">
                              As per global Journey rule, if the user is in
                              call, this {title}{" "}
                              <i>
                                {journey?.[`callBehaviour${title}s`]
                                  ? getCallBehaviourOptions()[
                                      journey?.[`callBehaviour${title}s`]
                                    ]
                                  : "is sent anyway"}
                              </i>
                              {journey?.[`callBehaviour${title}s`] === "delay"
                                ? `, after ${
                                    journey?.[`afterCallDelay${title}s`]
                                  } seconds`
                                : ""}
                            </Alert>
                          </Grid>
                        )}
                    </>
                  )}
                  {getFieldProps(formik, { name: "isFirstInFlow" }).value ===
                    true &&
                    getFieldProps(formik, { name: "callRelatedCustomization" })
                      .value === true && (
                      <Grid item md={6} xs={12}>
                        <CallBehaviourSelect
                          required
                          label="If the user is in call, this message:"
                          {...getFieldProps(formik, {
                            defaultValue:
                              formik.values.callBehaviour || "sendAnyway",
                            disabled: isDisabled,
                            name: "callBehaviour",
                          })}
                        ></CallBehaviourSelect>
                      </Grid>
                    )}
                  {getFieldProps(formik, { name: "isFirstInFlow" }).value ===
                    true &&
                    getFieldProps(formik, { name: "callRelatedCustomization" })
                      .value === true &&
                    getFieldProps(formik, { name: "callBehaviour" }).value ===
                      "delay" && (
                      <Grid item md={12} xs={12}>
                        <SelectorAlignedTypography>
                          Wait{"  "}
                          <SecondsSelectorWithinTypography
                            InputProps={{
                              inputProps: {
                                min: 0,
                                step: 1,
                              },
                            }}
                            {...getFieldProps(formik, {
                              disabled:
                                !getFieldProps(formik, {
                                  name: "callBehaviour",
                                }).value === "delay",
                              name: "afterCallDelay",
                            })}
                            placeholder="number"
                            type="number"
                          ></SecondsSelectorWithinTypography>{" "}
                          seconds after the call is finished before showing this
                          message.
                        </SelectorAlignedTypography>
                      </Grid>
                    )}
                  {/* Feature removed in task Simplify UI of tip creation in content portal (24-01-2024) 
              {getFieldProps(formik, { name: "isFirstInFlow" }).value ===
                true && (
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        {...getFieldProps(formik, {
                          defaultValue: false,
                          disabled:
                            isDisabled ||
                            !getFieldProps(formik, {
                              name: "isFirstInFlow",
                            }).value,
                          name: "miniTipAutoProgress",
                        })}
                        checked={
                          getFieldProps(formik, {
                            name: "miniTipAutoProgress",
                          }).value
                        }
                      />
                    }
                    label={`After the card-preview is received, automatically open it into card after (in seconds): `}
                  />
                  <SecondsSelector
                    InputProps={{
                      inputProps: {
                        min: 0,
                        step: 1,
                      },
                    }}
                    {...getFieldProps(formik, {
                      disabled: !getFieldProps(formik, {
                        name: "miniTipAutoProgress",
                      }).value,
                      name: "miniTipAutoProgressDelay",
                    })}
                    placeholder="number"
                    type="number"
                  ></SecondsSelector>
                </Grid>
              )}
              {getFieldProps(formik, { name: "isFirstInFlow" }).value ===
                true &&
                journey?.miniTipAutoProgress === true &&
                !formik.values.miniTipAutoProgress && (
                  <Grid item xs={12}>
                    <Alert severity="warning">
                      {journey?.miniTipAutoProgressOnlyIfIgnored === true
                        ? `As per
                    global Journey rule, after the card-preview is received, if
                    the user has already received it, but not opened, at least ${
                      journey?.miniTipAutoProgressOnlyIfIgnoredTimes ??
                      "missing-value"
                    } times, it
                    automatically opens after ${
                      journey?.miniTipAutoProgressDelay ?? "missing-value"
                    } seconds`
                        : `As per global Journey rule, after the card-preview is received, it
                    automatically opens after ${
                      journey?.miniTipAutoProgressDelay ?? "missing-value"
                    } seconds`}
                    </Alert>
                  </Grid>
                )} */}
                  {getFieldProps(formik, { name: "isFirstInFlow" }).value ===
                    true &&
                    getFieldProps(formik, { name: "miniTipAutoProgress" })
                      .value === true && (
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              {...getFieldProps(formik, {
                                defaultValue: false,
                                disabled:
                                  isDisabled ||
                                  !getFieldProps(formik, {
                                    name: "isFirstInFlow",
                                  }).value,
                                name: "miniTipAutoProgressOnlyIfIgnored",
                              })}
                              checked={
                                getFieldProps(formik, {
                                  name: "miniTipAutoProgressOnlyIfIgnored",
                                }).value
                              }
                            />
                          }
                          label={`Apply the logic above only if the user has already received, but not opened, that card-preview at least this many times: `}
                        />
                        <TimesSelector
                          InputProps={{
                            inputProps: {
                              min: 1,
                              step: 1,
                            },
                          }}
                          {...getFieldProps(formik, {
                            defaultValue: 1,
                            disabled:
                              isDisabled ||
                              !getFieldProps(formik, {
                                name: "miniTipAutoProgressOnlyIfIgnored",
                              }).value,
                            name: "miniTipAutoProgressOnlyIfIgnoredTimes",
                          })}
                          placeholder="number"
                          type="number"
                        ></TimesSelector>
                      </Grid>
                    )}
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h3">Message layout</Typography>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TemplateFamilySelect
                          required
                          label="Template Family"
                          {...getFieldProps(formik, {
                            defaultValue:
                              formik.values.templateFamilyId ||
                              formik.values.teams.templateFamilyId,
                            disabled: isDisabled,
                            name:
                              formik.values.viewType === "client"
                                ? "templateFamilyId"
                                : "teams.templateFamilyId",
                          })}
                          templatefamilies={
                            formik.values.viewType === "client"
                              ? templateFamilies
                              : teamsTemplateFamilies
                          }
                        ></TemplateFamilySelect>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <SemanticTemplateSelect
                          form={formik}
                          label="Card Template"
                          {...getFieldProps(formik, {
                            disabled:
                              (formik.values.viewType === "client" &&
                                semanticTemplateCatalogue?.length <= 0) ||
                              (formik.values.viewType === "teams_app" &&
                                teamsSemanticTemplateCatalogue?.length <= 0),
                            name:
                              formik.values.viewType === "client"
                                ? "semanticTemplateId"
                                : "teams.semanticTemplateId",
                            required: true,
                          })}
                          semantictemplatecatalogue={
                            formik.values.viewType === "client"
                              ? semanticTemplateCatalogue
                              : teamsSemanticTemplateCatalogue
                          }
                          templateFamily={
                            formik.values.viewType === "client"
                              ? templateFamilies.find(
                                  (family) =>
                                    formik.values.templateFamilyId ===
                                    family.tFamilyId
                                )?.tFamilyName
                              : teamsTemplateFamilies.find(
                                  (family) =>
                                    formik.values.teams.templateFamilyId ===
                                    family.tFamilyId
                                )?.tFamilyName
                          }
                        ></SemanticTemplateSelect>
                      </Grid>
                    </Grid>
                  </Grid>
                  {formik.values.viewType === "teams_app" && (
                    <>
                      <Grid item md={3} xs={12}>
                        <TextField
                          fullWidth
                          select
                          InputLabelProps={{ shrink: true }}
                          label={"Title Color"}
                          {...getFieldProps(formik, {
                            name: "teams.titleColor",
                            required: true,
                          })}
                          variant="outlined"
                          {...props}
                        >
                          <MenuItem value="Default">Default</MenuItem>
                          <MenuItem value="Dark">Dark</MenuItem>
                          <MenuItem value="Light">Light</MenuItem>
                          <MenuItem value="Accent">Accent</MenuItem>
                          <MenuItem value="Good">Good</MenuItem>
                          <MenuItem value="Warning">Warning</MenuItem>
                          <MenuItem value="Attention">Attention</MenuItem>
                        </TextField>
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <TextField
                          fullWidth
                          select
                          InputLabelProps={{ shrink: true }}
                          label={"Text Color"}
                          {...getFieldProps(formik, {
                            name: "teams.textColor",
                            required: true,
                          })}
                          variant="outlined"
                          {...props}
                        >
                          <MenuItem value="Default">Default</MenuItem>
                          <MenuItem value="Dark">Dark</MenuItem>
                          <MenuItem value="Light">Light</MenuItem>
                          <MenuItem value="Accent">Accent</MenuItem>
                          <MenuItem value="Good">Good</MenuItem>
                          <MenuItem value="Warning">Warning</MenuItem>
                          <MenuItem value="Attention">Attention</MenuItem>
                        </TextField>
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <TextField
                          fullWidth
                          select
                          InputLabelProps={{ shrink: true }}
                          {...getFieldProps(formik, {
                            name: "teams.titleSize",
                            required: true,
                          })}
                          label={"Title Size"}
                          variant="outlined"
                          {...props}
                        >
                          <MenuItem value="Default">Default</MenuItem>
                          <MenuItem value="Small">Small</MenuItem>
                          <MenuItem value="Medium">Medium</MenuItem>
                          <MenuItem value="Large">Large</MenuItem>
                          <MenuItem value="ExtraLarge">ExtraLarge</MenuItem>
                        </TextField>
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <TextField
                          fullWidth
                          select
                          InputLabelProps={{ shrink: true }}
                          {...getFieldProps(formik, {
                            name: "teams.textSize",
                            required: true,
                          })}
                          label={"Text Size"}
                          variant="outlined"
                          {...props}
                        >
                          <MenuItem value="Default">Default</MenuItem>
                          <MenuItem value="Small">Small</MenuItem>
                          <MenuItem value="Medium">Medium</MenuItem>
                          <MenuItem value="Large">Large</MenuItem>
                          <MenuItem value="ExtraLarge">ExtraLarge</MenuItem>
                        </TextField>
                      </Grid>
                    </>
                  )}
                  {formik.values.viewType === "client" && (
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item md={2} xs={3}>
                          <TextField
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            label={"Color Bubble"}
                            placeholder="#HexColorCode"
                            variant="outlined"
                            {...getFieldProps(formik, {
                              //defaultValue: formik.values.templateColors.color1 || formik.values.teams.backgroundColor,
                              disabled: isDisabled,
                              name: "templateColors.color1",
                            })}
                          ></TextField>
                        </Grid>
                        <Grid item md={2} xs={3}>
                          <TextField
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            label={"Color Text"}
                            placeholder="#HexColorCode"
                            variant="outlined"
                            {...getFieldProps(formik, {
                              defaultValue: formik.values.templateColors.color2,
                              disabled: isDisabled,
                              name: "templateColors.color2",
                            })}
                          ></TextField>
                        </Grid>
                        <Grid item md={2} xs={3}>
                          <TextField
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            label={"Color Card"}
                            placeholder="#HexColorCode"
                            variant="outlined"
                            {...getFieldProps(formik, {
                              defaultValue: formik.values.templateColors.color3,
                              disabled: isDisabled,
                              name: "templateColors.color3",
                            })}
                          ></TextField>
                        </Grid>

                        {formik.values.viewType === "client" &&
                          selectedTemplate !== LEGACY_TEMPLATEFAMILY_ID && (
                            <Grid item md={2} xs={3}>
                              <TextField
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                label={"Color Circle"}
                                placeholder="#HexColorCode"
                                variant="outlined"
                                {...getFieldProps(formik, {
                                  defaultValue:
                                    formik.values.templateColors.color4,
                                  disabled: isDisabled,
                                  name: "templateColors.color4",
                                })}
                              ></TextField>
                            </Grid>
                          )}

                        <Grid item md={2} xs={3}>
                          <TextField
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            label={"ColorCTAtext"}
                            placeholder="#HexColorCode"
                            variant="outlined"
                            {...getFieldProps(formik, {
                              defaultValue:
                                formik.values.templateColors.color1cta,
                              disabled: isDisabled,
                              name: "templateColors.color1cta",
                            })}
                          ></TextField>
                        </Grid>
                        <Grid item md={2} xs={3}>
                          <TextField
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            label={"ColorCTABackground"}
                            placeholder="#HexColorCode"
                            variant="outlined"
                            {...getFieldProps(formik, {
                              defaultValue:
                                formik.values.templateColors.color2cta,
                              disabled: isDisabled,
                              name: "templateColors.color2cta",
                            })}
                          ></TextField>
                        </Grid>
                        <Grid item md={1} xs={1}>
                          <Grid
                            container
                            alignItems={
                              selectedTemplate === LEGACY_TEMPLATEFAMILY_ID
                                ? "center"
                                : ""
                            }
                            spacing={1}
                            style={{ flexDirection: "column" }}
                          >
                            <Grid item md={1} xs={1}>
                              <Typography
                                color="textSecondary"
                                variant="caption"
                              >
                                Pick&Copy
                              </Typography>
                            </Grid>
                            <Grid item md={1} xs={1}>
                              <input
                                type="color"
                                onChange={(e) =>
                                  navigator.clipboard.writeText(e.target.value)
                                }
                              ></input>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item md={2} xs={3}>
                          <TextField
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            label={"TitleFontSize"}
                            placeholder="ex.: 16px"
                            variant="outlined"
                            {...getFieldProps(formik, {
                              defaultValue:
                                formik.values.templateFonts.titlefontsize,
                              disabled: isDisabled,
                              name: "templateFonts.titlefontsize",
                            })}
                          ></TextField>
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <FontFamilySelect
                            label="TitleFontFamily"
                            {...getFieldProps(formik, {
                              defaultValue:
                                formik.values.templateFonts.titlefontfamily,
                              disabled: isDisabled,
                              name: "templateFonts.titlefontfamily",
                            })}
                          ></FontFamilySelect>
                        </Grid>
                        <Grid item md={2} xs={3}>
                          <TextField
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            label={"TextFontSize"}
                            placeholder="ex.: 16px"
                            variant="outlined"
                            {...getFieldProps(formik, {
                              defaultValue:
                                formik.values.templateFonts.textfontsize,
                              disabled: isDisabled,
                              name: "templateFonts.textfontsize",
                            })}
                          ></TextField>
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <FontFamilySelect
                            label="TextFontFamily"
                            {...getFieldProps(formik, {
                              defaultValue:
                                formik.values.templateFonts.textfontfamily,
                              disabled: isDisabled,
                              name: "templateFonts.textfontfamily",
                            })}
                          ></FontFamilySelect>
                        </Grid>
                        <Grid item md={2} xs={3}>
                          <TextField
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            label={"CtaFontSize"}
                            placeholder="ex.: 16px"
                            variant="outlined"
                            {...getFieldProps(formik, {
                              defaultValue:
                                formik.values.templateFonts.ctafontsize,
                              disabled: isDisabled,
                              name: "templateFonts.ctafontsize",
                            })}
                          ></TextField>
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <FontFamilySelect
                            label="CtaFontFamily"
                            {...getFieldProps(formik, {
                              defaultValue:
                                formik.values.templateFonts.ctafontfamily,
                              disabled: isDisabled,
                              name: "templateFonts.ctafontfamily",
                            })}
                          ></FontFamilySelect>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {/* Feature removed in task Simplify UI of tip creation in content portal (24-01-2024)
              {formik.values.isFirstInFlow && (
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    
                     <Grid item xs={12}>
                      <Typography variant="h6">
                        Card-Preview settings
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <SemanticPreviewTemplateSelect
                        defaultsemanticpreviewtemplateid={
                          semanticTemplates?.[0]
                            ?.defaultSemanticPreviewTemplateId
                        }
                        label="Card-Preview Type"
                        // selectedFamily={formik.values.familyTemplate}
                        {...getFieldProps(formik, {
                          disabled:
                            semanticTemplateCatalogue?.length <= 0 ||
                            !semanticTemplates ||
                            !semanticTemplates.length ||
                            !semanticPreviewTemplateCatalogue,
                          name: "semanticPreview.semanticPreviewTemplateId",
                          required: formik.values.isFirstInFlow,
                        })}
                        semanticpreviewtemplatecatalogue={
                          semanticPreviewTemplateCatalogue
                        }
                      ></SemanticPreviewTemplateSelect>
                    </Grid> 
                    {/* {getFieldProps(formik, { name: "templateFamilyId" })
                      .value === LEGACY_TEMPLATEFAMILY_ID &&
                    formik.values.type !== "PRIME" ? (
                      <Grid item md={6} xs={12}>
                        <Alert severity="info">
                          Note: by default there are <b>no previews</b> for{" "}
                          <b>nudges</b> in <b>Legacy</b> template family
                        </Alert>
                      </Grid>
                    ) : (
                      ""
                    )} */}
                  {/* {formik.values.templateFamilyId &&
                    formik.values.semanticTemplateId &&
                    formik.values.semanticPreview?.semanticPreviewTemplateId &&
                    semanticPreviewTemplateCatalogue.filter(
                      (t) =>
                        t.templateId ===
                          formik.values.semanticPreview
                            .semanticPreviewTemplateId && t.isActive === true
                    ).length <= 0 ? (
                      <Grid item md={6} xs={12}>
                        <Alert severity="warning">
                          Warning: incompatible Card-Template & Card-Preview
                          type
                        </Alert>
                      </Grid>
                    ) : (
                      ""
                    )}
                    <Grid item xs={12}>
                      <MarkdownEditor
                        error={false}
                        label={`Card-Preview title`}
                        placeholder={`Try to catch the user's attention and introduce the ${title}'s topic.`}
                        {...getFieldProps(formik, {
                          disabled:
                            isDisabled ||
                            isSemanticPreviewFieldDisabled(
                              "semanticPreview.title"
                            ),
                          name: "semanticPreview.title",
                        })}
                        value={interaction.semanticPreview?.title}
                      />
                      <FormHelperText>
                        This field has no character limit. For a better result,
                        we suggest a length around 14 characters. Your title is
                        currently{" "}
                        {removeMarkdown(
                          formik.values.semanticPreview.title || ""
                        )?.length ?? 0}{" "}
                        characters long.
                      </FormHelperText>
                    </Grid>
                    <Grid item md={2} xs={3}>
                      <TextField
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        label={"Preview TitleFontSize"}
                        placeholder="ex.: 16px"
                        variant="outlined"
                        {...getFieldProps(formik, {
                          defaultValue:
                            formik.values.semanticPreview.titlefontsize,
                          disabled: isDisabled,
                          name: "semanticPreview.titlefontsize",
                        })}
                      ></TextField>
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <FontFamilySelect
                        label="Preview TitleFontFamily"
                        {...getFieldProps(formik, {
                          defaultValue:
                            formik.values.semanticPreview.titlefontfamily,
                          disabled: isDisabled,
                          name: "semanticPreview.titlefontfamily",
                        })}
                      ></FontFamilySelect>
                    </Grid>

                    <Grid item xs={12}>
                      <>
                        {formik.values.semanticPreview.text}
                        <MarkdownEditor
                          error={false}
                          label={`Card-Preview text`}
                          placeholder={`Deep dive into the ${title}'s topic and showcase the value for the user.`}
                          {...getFieldProps(formik, {
                            disabled:
                              isDisabled ||
                              isSemanticPreviewFieldDisabled(
                                "semanticPreview.text"
                              ),
                            name: "semanticPreview.text",
                          })}
                          // value={interaction.semanticPreview?.text}
                        />
                        <FormHelperText>
                          This field has no character limit. For a better
                          result, we suggest a length around 60 characters. Your
                          text is currently{" "}
                          {removeMarkdown(
                            formik.values.semanticPreview.text || ""
                          )?.length ?? 0}{" "}
                          characters long.
                        </FormHelperText>
                      </>
                    </Grid>
                    <Grid item md={2} xs={3}>
                      <TextField
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        label={"Preview TextFontSize"}
                        placeholder="ex.: 16px"
                        variant="outlined"
                        {...getFieldProps(formik, {
                          defaultValue:
                            formik.values.semanticPreview.textfontsize,
                          disabled: isDisabled,
                          name: "semanticPreview.textfontsize",
                        })}
                      ></TextField>
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <FontFamilySelect
                        label="Preview TextFontFamily"
                        {...getFieldProps(formik, {
                          defaultValue:
                            formik.values.semanticPreview.textfontfamily,
                          disabled: isDisabled,
                          name: "semanticPreview.textfontfamily",
                        })}
                      ></FontFamilySelect>
                    </Grid>
                    <Grid item md={4}></Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2">
                        Card-Preview cta settings
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Input
                        label="Card-Preview CTA text"
                        maxLength={25}
                        placeholder="Be accurate, specific, explicit. Don't be vague."
                        {...getFieldProps(formik, {
                          disabled:
                            isDisabled ||
                            isSemanticPreviewFieldDisabled(
                              "semanticPreview.cta1Text"
                            ),
                          name: "semanticPreview.cta1Text",
                        })}
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <FontFamilySelect
                        label="Preview CtaFontFamily"
                        {...getFieldProps(formik, {
                          defaultValue:
                            formik.values.semanticPreview.ctafontfamily,
                          disabled: isDisabled,
                          name: "semanticPreview.ctafontfamily",
                        })}
                      ></FontFamilySelect>
                    </Grid>
                  </Grid>
                </Grid>
              )} */}
                  {formik.values.viewType === "client" && (
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="primary"
                                {...getFieldProps(formik, {
                                  defaultValue: true,
                                  name: "showX",
                                })}
                                checked={
                                  getFieldProps(formik, {
                                    name: "showX",
                                  }).value
                                }
                              />
                            }
                            label={`Show the X to close the message`}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid
                            item
                            xs={formik.values.viewType === "teams_app" ? 6 : 4}
                          >
                            <Grid container alignItems="center" spacing={1}>
                              <Grid item>
                                <Typography variant="subtitle2">
                                  MAIN IMAGE
                                </Typography>
                              </Grid>
                            </Grid>
                            {formik.values.viewType === "client" && (
                              <ImageUpload
                                buttonProps={{
                                  children: "Upload",
                                }}
                                caption={
                                  <Typography
                                    color="textSecondary"
                                    style={{ color: "#BFBFBF" }}
                                  >
                                    Upload .jpg or .png files.
                                  </Typography>
                                }
                                customPathInfo={{ journeyId: journey.id }}
                                disabled={isDisabled}
                                styles={{ width: 200 }}
                                value={formik.values.imageId}
                                onChange={handleImageChangeImageID}
                                onReset={handleImageResetImageID}
                              />
                            )}
                            {formik.values.viewType === "teams_app" && (
                              <ImageUpload
                                buttonProps={{
                                  children: "Upload",
                                }}
                                caption={
                                  <Typography
                                    color="textSecondary"
                                    style={{ color: "#BFBFBF" }}
                                  >
                                    Upload .jpg or .png files.
                                  </Typography>
                                }
                                customPathInfo={{ journeyId: journey.id }}
                                disabled={isDisabled}
                                styles={{ width: 200 }}
                                value={formik.values.teams.mainImage}
                                onChange={handleImageChangeMainImage}
                                onReset={handleImageResetMainImage}
                              />
                            )}
                            {formik.values.viewType === "teams_app" &&
                              formik.values?.teams?.mainImage && (
                                <Grid
                                  container
                                  spacing={2}
                                  style={{ marginTop: "10px" }}
                                >
                                  <Grid item md={3} xs={12}>
                                    <TextField
                                      fullWidth
                                      InputLabelProps={{ shrink: true }}
                                      {...getFieldProps(formik, {
                                        name: "teams.imageWidth",
                                      })}
                                      label="Image Width"
                                      placeholder="ex.: 16px"
                                      variant="outlined"
                                    ></TextField>
                                  </Grid>
                                  <Grid item md={3} xs={12}>
                                    <TextField
                                      fullWidth
                                      InputLabelProps={{ shrink: true }}
                                      {...getFieldProps(formik, {
                                        name: "teams.imageHeight",
                                      })}
                                      label="Image Height"
                                      placeholder="ex.: 16px"
                                      variant="outlined"
                                    ></TextField>
                                  </Grid>
                                </Grid>
                              )}
                          </Grid>

                          {formik.values.viewType === "client" && (
                            <Grid item xs={4}>
                              <Grid container alignItems="center" spacing={1}>
                                <Grid item>
                                  <Typography variant="subtitle2">
                                    BLOB BACKGROUND
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <InfoTooltip
                                    text="Scales the image (while preserving its ratio) to the smallest possible size to fill the container (that is: both its height and width completely cover the container), leaving no empty space. If the proportions of the background differ from the element, the image is cropped either vertically or horizontally."
                                    title="Image Dimensions"
                                  />
                                </Grid>
                              </Grid>
                              <ImageUpload
                                buttonProps={{
                                  children: "Upload",
                                }}
                                caption={
                                  <Typography
                                    color="textSecondary"
                                    style={{ color: "#BFBFBF" }}
                                  >
                                    Upload .jpg or .png files.
                                  </Typography>
                                }
                                customPathInfo={{ journeyId: journey.id }}
                                disabled={isDisabled}
                                styles={{ width: 200 }}
                                value={formik.values.images?.bg1}
                                onChange={handleImageChangeBG1}
                                onReset={handleImageResetBG1}
                              />
                            </Grid>
                          )}
                          {formik.values.viewType === "teams_app" && (
                            <Grid item xs={6}>
                              <Grid container alignItems="center" spacing={1}>
                                <Grid item>
                                  <Typography variant="subtitle2">
                                    CARD BACKGROUND
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <InfoTooltip
                                    text="Scales the image (while preserving its ratio) to the smallest possible size to fill the container (that is: both its height and width completely cover the container), leaving no empty space. If the proportions of the background differ from the element, the image is cropped either vertically or horizontally."
                                    title="Image Dimensions"
                                  />
                                </Grid>
                              </Grid>
                              <ImageUpload
                                buttonProps={{
                                  children: "Upload",
                                }}
                                caption={
                                  <Typography
                                    color="textSecondary"
                                    style={{ color: "#BFBFBF" }}
                                  >
                                    Upload .jpg or .png files.
                                  </Typography>
                                }
                                customPathInfo={{ journeyId: journey.id }}
                                disabled={isDisabled}
                                styles={{ width: 200 }}
                                value={formik.values.teams?.backgroundImage}
                                onChange={handleImageChangeBackgroundImage}
                                onReset={handleImageResetBackgroundImage}
                              />
                              {formik.values.viewType === "teams_app" && (
                                <Grid
                                  container
                                  spacing={2}
                                  style={{ marginTop: "10px" }}
                                >
                                  <Grid item md={6} xs={6}>
                                    <Grid
                                      container
                                      alignItems="left"
                                      spacing={1}
                                      style={{ flexDirection: "column" }}
                                    >
                                      <Grid item md={12} xs={12}>
                                        <Typography
                                          color="textSecondary"
                                          variant="caption"
                                        >
                                          Background color
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        container
                                        alignItems="center"
                                        spacing={1}
                                        style={{ flexDirection: "row" }}
                                      >
                                        <Grid item md={4} xs={4}>
                                          <input
                                            type="color"
                                            {...getFieldProps(formik, {
                                              disabled: isDisabled,
                                              name: "teams.backgroundColor",
                                            })}
                                          ></input>
                                        </Grid>
                                        <Grid item md={5} xs={5}>
                                          <TextField
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            label={""}
                                            placeholder="#HexCode"
                                            size="small"
                                            variant="outlined"
                                            {...getFieldProps(formik, {
                                              disabled: isDisabled,
                                              name: "teams.backgroundColor",
                                            })}
                                          ></TextField>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              )}
                            </Grid>
                          )}
                          {formik.values.viewType !== "teams_app" && (
                            <Grid item xs={4}>
                              <Grid container alignItems="center" spacing={1}>
                                <Grid item>
                                  <Typography variant="subtitle2">
                                    CARD BACKGROUND
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <InfoTooltip
                                    text="Scales the image (while preserving its ratio) to the smallest possible size to fill the container (that is: both its height and width completely cover the container), leaving no empty space. If the proportions of the background differ from the element, the image is cropped either vertically or horizontally."
                                    title="Image Dimensions"
                                  />
                                </Grid>
                              </Grid>
                              <ImageUpload
                                buttonProps={{
                                  children: "Upload",
                                }}
                                caption={
                                  <Typography
                                    color="textSecondary"
                                    style={{ color: "#BFBFBF" }}
                                  >
                                    Upload .jpg or .png files.
                                  </Typography>
                                }
                                customPathInfo={{ journeyId: journey.id }}
                                disabled={isDisabled}
                                styles={{ width: 200 }}
                                value={formik.values.images?.bg2}
                                onChange={handleImageChangeBG2}
                                onReset={handleImageResetBG2}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item style={{ marginTop: "1rem" }} xs={12}>
                    <Grid item style={{ marginBottom: "1.5rem" }} xs={12}>
                      <Typography variant="h3">Message Content</Typography>
                    </Grid>
                    <MarkdownEditor
                      error={false}
                      label={`${title} title`}
                      placeholder={`Try to catch the user's attention and introduce the ${title}'s topic.`}
                      {...getFieldProps(formik, {
                        disabled: isDisabled,
                        name: "title",
                      })}
                      secondary={true}
                      value={aiResult ? aiResult.title : interaction.title}
                      //value={interaction.title}
                    />

                    <FormHelperText>
                      This field has no character limit. For a better result, we
                      suggest a length around 60 characters. Your title is
                      currently{" "}
                      {removeMarkdown(formik.values.title || "")?.length ?? 0}{" "}
                      characters long.
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12}>
                    {formik.values.type === "NUDGE" && (
                      <>
                        <MarkdownEditor
                          error={false}
                          label={`${title} text`}
                          placeholder={`Enter ${title} text...`}
                          {...getFieldProps(formik, {
                            disabled: isDisabled,
                            name: "text",
                          })}
                          secondary={true}
                          value={interaction.text}
                        />
                      </>
                    )}

                    {formik.values.type === "PRIME" && (
                      <>
                        <MarkdownEditor
                          error={false}
                          label={`${title} text`}
                          placeholder={`Deep dive into the ${title}'s topic and showcase the value for the user.`}
                          {...getFieldProps(formik, {
                            disabled: isDisabled,
                            name: "text",
                          })}
                          secondary={true}
                          value={aiResult ? aiResult.text : interaction.text}
                          //value={interaction.text}
                        />
                        <FormHelperText>
                          This field has no character limit. For a better
                          result, we suggest a length around 250 characters.
                          Your text is currently{" "}
                          {removeMarkdown(formik.values.text || "")?.length ??
                            0}{" "}
                          characters long.
                        </FormHelperText>
                      </>
                    )}
                  </Grid>
                  {(interaction.type === "PRIME" ||
                    (interaction.type === "NUDGE" &&
                      (!contentTypeConfig ||
                        contentTypeConfig?.ctaTypes?.length > 1))) && (
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography
                            style={{ marginTop: "1rem" }}
                            variant="h3"
                          >
                            CTA Configuration
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <ToggleButtonGroup
                            exclusive
                            size="small"
                            value={formik.values.ctaType}
                            onChange={handleTypeChange}
                          >
                            {(interaction.type === "PRIME" ||
                              (interaction.type === "NUDGE" &&
                                (!contentTypeConfig ||
                                  contentTypeConfig?.ctaTypes.includes(
                                    "CTA"
                                  )))) && (
                              <ToggleButton disabled={isDisabled} value="CTA">
                                Single CTA
                              </ToggleButton>
                            )}

                            {(interaction.type === "PRIME" ||
                              (interaction.type === "NUDGE" &&
                                (!contentTypeConfig ||
                                  contentTypeConfig?.ctaTypes.includes(
                                    "DOUBLE_CTA"
                                  )))) && (
                              <ToggleButton
                                disabled={isDisabled}
                                value="DOUBLE_CTA"
                              >
                                Double CTA
                              </ToggleButton>
                            )}

                            <ToggleButton
                              disabled={isDisabled}
                              value="DROPDOWN"
                            >
                              Dropdown menu
                            </ToggleButton>
                          </ToggleButtonGroup>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {formik.values.ctaType === "DROPDOWN" &&
                    formik.values.dropdownData
                      ?.slice(1)
                      .some(
                        ({ ctaType }) =>
                          ctaType && !["Semantic", "Close"].includes(ctaType)
                      ) && (
                      <Grid item xs={12}>
                        <AlertWithShowMore severity="warning">
                          {({ showMore, isShowMore }) => (
                            <>
                              This configuration will not work in teams app.{" "}
                              <Link
                                style={{ cursor: "pointer" }}
                                onClick={showMore}
                              >
                                {isShowMore ? "Hide more" : "Discover more"}
                              </Link>
                              {isShowMore && (
                                <Box pt={3}>
                                  Dropdown options can do one of the following
                                  only: redirecting to another semantic OR
                                  closing.
                                </Box>
                              )}
                            </>
                          )}
                        </AlertWithShowMore>
                      </Grid>
                    )}
                  <Grid item xs={12}>
                    {formik.values.ctaType === "CTA" && (
                      <CTA
                        aiResult={aiResult}
                        formik={formik}
                        handleCreateMaterial={handleCreateMaterial(
                          "cta1LinkId"
                        )}
                        handleCreateSpecialLink={handleCreateSpecialLink(
                          "cta1LinkId"
                        )}
                        interactions={interactions}
                        isDisabled={isDisabled}
                        isListJourneyBenefitsLoading={
                          isListJourneyBenefitsLoading
                        }
                        isListJourneyNewsLoading={isListJourneyNewsLoading}
                        isMaterialsLoading={isMaterialsLoading}
                        isSpecialLinksLoading={isSpecialLinksLoading}
                        isSurveysLoading={isSurveysLoading}
                        journeyBenefits={journeyBenefits}
                        journeyNews={journeyNews}
                        materials={materials}
                        prompts={prompts}
                        specialLinks={specialLinks}
                        steps={journey?.steps ?? []}
                        surveys={surveys}
                        tools={tools}
                        types={contentTypeConfig?.cta1Types}
                      />
                    )}
                    {formik.values.ctaType === "DOUBLE_CTA" && (
                      <CTA
                        aiResult={aiResult}
                        formik={formik}
                        handleCreateMaterial={handleCreateMaterial(
                          "cta1LinkId"
                        )}
                        handleCreateSpecialLink={handleCreateSpecialLink(
                          "cta1LinkId"
                        )}
                        index={1}
                        interactions={interactions}
                        isDisabled={isDisabled}
                        isListJourneyBenefitsLoading={
                          isListJourneyBenefitsLoading
                        }
                        isListJourneyNewsLoading={isListJourneyNewsLoading}
                        isMaterialsLoading={isMaterialsLoading}
                        isSpecialLinksLoading={isSpecialLinksLoading}
                        journeyBenefits={journeyBenefits}
                        journeyNews={journeyNews}
                        materials={materials}
                        prompts={prompts}
                        specialLinks={specialLinks}
                        steps={journey?.steps ?? []}
                        surveys={surveys}
                        tools={tools}
                        types={contentTypeConfig?.cta1Types}
                      />
                    )}
                    {formik.values.ctaType === "DOUBLE_CTA" && (
                      <CTA
                        aiResult={aiResult}
                        formik={formik}
                        handleCreateMaterial={handleCreateMaterial(
                          "cta2LinkId"
                        )}
                        handleCreateSpecialLink={handleCreateSpecialLink(
                          "cta2LinkId"
                        )}
                        index={2}
                        interactions={interactions}
                        isDisabled={isDisabled}
                        isListJourneyBenefitsLoading={
                          isListJourneyBenefitsLoading
                        }
                        isListJourneyNewsLoading={isListJourneyNewsLoading}
                        isMaterialsLoading={isMaterialsLoading}
                        isSpecialLinksLoading={isSpecialLinksLoading}
                        journeyBenefits={journeyBenefits}
                        journeyNews={journeyNews}
                        materials={materials}
                        prompts={prompts}
                        specialLinks={specialLinks}
                        steps={journey?.steps ?? []}
                        surveys={surveys}
                        tools={tools}
                        types={contentTypeConfig?.cta2Types}
                      />
                    )}
                    {formik.values.ctaType === "DROPDOWN" && (
                      <Grid container spacing={4}>
                        <Grid item md={6} xs={12}>
                          <Input
                            {...getFieldProps(formik, {
                              name: "dropdownData.0.label",
                            })}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            disabled={isDisabled}
                            label="Placeholder text"
                            maxLength={25}
                            value={formik.values.dropdownData?.[0]?.label || ""}
                            variant="outlined"
                            onChange={handleDropdownDataChange(0, "label")}
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Input
                            label="CTA text"
                            maxLength={25}
                            placeholder="Be accurate, specific, explicit. Don't be vague."
                            {...getFieldProps(formik, {
                              disabled: isDisabled,
                              name: "cta1Text",
                            })}
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <UserStatePathSelect
                            required
                            isLoading={isUserStatePathsLoading}
                            journeys={journeys.filter(
                              (journey) => locale === journey.locale
                            )}
                            label="Field assigned"
                            userStatePaths={userStatePaths}
                            value={formik.values.userStatePath}
                            onChange={(value) => {
                              if (
                                [formik.values.userStatePath, value].some(
                                  (value) => value === "currentJourney"
                                )
                              ) {
                                formik.values.dropdownData
                                  .slice(1)
                                  .forEach((_, i) => {
                                    formik.setFieldValue(
                                      `dropdownData.${i + 1}.value`,
                                      ""
                                    );
                                    formik.setFieldTouched(
                                      `dropdownData.${i + 1}.value`,
                                      true
                                    );
                                  });
                              }

                              formik.setFieldValue("userStatePath", value);
                              formik.setFieldTouched("userStatePath", true);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="h6">Options</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={4}>
                            {(formik.values.dropdownData || [])
                              .slice(1)
                              .map(({ value, label, id }, i) => (
                                <Option
                                  key={id}
                                  formik={formik}
                                  i={i}
                                  interactions={interactions}
                                  isDeleteEnabled={
                                    formik.values.dropdownData?.length > 2
                                  }
                                  isDisabled={isDisabled}
                                  isListJourneyBenefitsLoading={
                                    isListJourneyBenefitsLoading
                                  }
                                  isListJourneyNewsLoading={
                                    isListJourneyNewsLoading
                                  }
                                  isSurveysLoading={isSurveysLoading}
                                  journeyBenefits={journeyBenefits}
                                  journeyNews={journeyNews}
                                  journeys={journeys}
                                  label={label}
                                  locale={locale}
                                  materials={materials}
                                  prompts={prompts}
                                  specialLinks={specialLinks}
                                  steps={journey?.steps}
                                  surveys={surveys}
                                  tools={tools}
                                  value={value}
                                  onCreateMaterial={handleCreateMaterial(
                                    `dropdownData.${i + 1}.ctaTarget`
                                  )}
                                  onCreateSpecialLink={handleCreateSpecialLink(
                                    `dropdownData.${i + 1}.ctaTarget`
                                  )}
                                  onDropdownDataChange={
                                    handleDropdownDataChange
                                  }
                                  onDropdownDataDelete={
                                    handleDropdownDataDelete
                                  }
                                />
                              ))}
                            <Grid item xs={12}>
                              <Button
                                color="primary"
                                disabled={isDisabled}
                                startIcon={<AddIcon />}
                                variant="outlined"
                                onClick={handleDropdownDataChange(
                                  formik.values.dropdownData?.length || 1,
                                  "value"
                                )}
                              >
                                Add option
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Sticky
                  scrollElement=".scrollarea"
                  stickyStyle={{ top: theme.spacing(4) + 92 }}
                >
                  <div ref={previewContainerRef}>
                    <Grid container id="preview" justify="flex-end">
                      <Grid item>
                        <Grid
                          container
                          spacing={2}
                          style={{
                            flexDirection: "column",
                            height,
                          }}
                        >
                          <Grid item>
                            <Title variant="h5">Simulation</Title>
                            <Subtitle color="textSecondary">
                              Save all the changes in order to see your latest
                              modifications to this {title}.
                            </Subtitle>
                          </Grid>
                          <Preview
                            semantic={formik.values}
                            src={`${
                              process.env
                                .REACT_APP_account_content_dashboard_render_url
                            }?silent=true&preview=true&props=${window.btoa(
                              JSON.stringify({
                                did: `preview_${locale}`,
                                leafVersion: "24.0.220",
                                semanticId: formik.values.id,
                              })
                            )}&rerender=${window.btoa(
                              encodeURIComponent(JSON.stringify(interaction))
                            )}
                        `}
                            title="preview"
                            userId={`preview_${locale}`}
                            viewType={formik.values.viewType}
                          >
                            {/*
                          leafVersion is mandatory to show the preview (ex mini-tip)
                          leafVersion 24.0.220 is used just from here
                          if you omit leafVersion, preview won't be displayed
                          rerender param only triggers a new re-render (url changes)
                        */}
                            {({
                              iframe,
                              isExportImageProcessing,
                              isSizeReceived,
                              reload,
                              callExportImageLambda,
                            }) => (
                              <>
                                <Grid item>
                                  <Grid container spacing={1}>
                                    <Grid item>
                                      {/* <Box display="flex"> */}
                                      <Button
                                        color="primary"
                                        endIcon={<AutorenewIcon />}
                                        size="small"
                                        variant="outlined"
                                        onClick={reload}
                                      >
                                        RESET
                                      </Button>
                                    </Grid>
                                    {/* <Box ml={2}>
                                  <Button
                                    color="primary"
                                    endIcon={<GetAppIcon />}
                                    size="small"
                                    variant="outlined"
                                    onClick={openExportImage}
                                  >
                                    Export
                                  </Button>
                                </Box> */}
                                    {/* </Box> */}
                                    <Grid item>
                                      {/* <Box ml={2}> */}
                                      <Button
                                        color="primary"
                                        disabled={
                                          !isSizeReceived ||
                                          isExportImageProcessing
                                        }
                                        endIcon={<ImageIcon />}
                                        size="small"
                                        variant="outlined"
                                        onClick={callExportImageLambda}
                                      >
                                        Export
                                      </Button>
                                      {/* </Box> */}
                                    </Grid>
                                    {isExportImageProcessing && (
                                      <Grid item>
                                        <Subtitle color="textSecondary">
                                          ..it may take up to ~ 20s
                                        </Subtitle>
                                        <Grid item xs={12}>
                                          <LinearProgress />
                                        </Grid>
                                      </Grid>
                                    )}
                                  </Grid>
                                </Grid>
                                <Grid
                                  item
                                  style={{ flex: 1, overflow: "auto" }}
                                  xs={12}
                                >
                                  {iframe}
                                </Grid>
                              </>
                            )}
                          </Preview>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </Sticky>
              </Grid>
              <Grid
                item
                style={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                xs={8}
              >
                {(user.groups.includes("Admins") ||
                  !user.companies?.includes(
                    "9aa46b2c-5fa6-4f0c-839d-35527dd19386"
                  )) && (
                  <>
                    <Grid>
                      <Typography style={{ fontWeight: "bold" }} variant="h6">
                        Looking for some inspiration? h{"(A)"}i is here to help
                        you!
                      </Typography>
                    </Grid>
                    <Button
                      color="primary"
                      style={{ marginLeft: "2%" }}
                      variant="contained"
                      onClick={handleAiClick}
                    >
                      Start the experience!
                    </Button>
                  </>
                )}
              </Grid>
              <Grid item xs={12}>
                <SaveButtons formik={formik} isNew={isNew} />
              </Grid>
              {/* <Grid container direction="column">
            <Grid
              item
              alignItems="center"
              justifyContent="center"
              p={2}
              style={{ paddingLeft: "30px", paddingRight: "30px" }}
              xs={8}
            >
              <AnalyticsReport
                elementOfInterest={formik.values.id}
                reportSelector={"semantic"}
              ></AnalyticsReport>
            </Grid>
          </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

const EditInteraction = () => {
  const match = useRouteMatch();
  const { journeyId, locale, semanticId } = match.params;

  const { state } = useLocation();

  const {
    semantics,
    fetchSemantics,
    isSemanticsLoading,
    fetchJourneys,
    journeys,
    isJourneysLoading,
    // user,
    isTriggerConfigsLoading,
    isTriggersLoading,
    fetchTriggers,
    fetchTriggerConfigs,
    fetchGroups,
    isGroupsLoading,
    groups,
    triggers,
    triggerConfigs,
    fetchFlowRelations,
    isFlowRelationsLoading,
    flowRelations,
    scheduledSlots,
    fetchScheduledSlots,
    fetchUserStatePaths,
    fetchAllSurveysForUser: fetchSurveys,
    tools,
    fetchTools,
    prompts,
    fetchPrompts,
  } = useAppContext();

  useEffect(() => {
    if (!flowRelations.length) {
      fetchFlowRelations({ journeyId, locale });
    }
    if (!semantics.length) {
      fetchSemantics({ journeyId, locale });
    }

    if (!scheduledSlots.length) {
      fetchScheduledSlots({ journeyId });
    }

    if (!journeys.length) {
      fetchJourneys();
    }
    if (!tools.length) {
      fetchTools();
    }
    if (!prompts.length) {
      fetchPrompts();
    }
  }, [
    journeyId,
    journeys.length,
    locale,
    semantics.length,
    flowRelations.length,
    scheduledSlots.length,
  ]);

  const { isNew, type, flowId, stimulusId } = state || {};
  const interaction = useMemo(
    () =>
      isNew && type === "PRIME"
        ? {
            cta1Type: "Close",
            cta2Type: "Close",
            ctaType: "CTA",
            flowId: flowId || `flow-${uuidv4()}`,
            id: semanticId,
            index: 0,
            isFirstInFlow: !flowId,
            isNew,
            journeyId,
            semanticId,
            semanticPreview: {},
            showX: true,
            stimulusId: "S_P999",
            templateColors: {},
            type,
          }
        : isNew && type === "NUDGE"
        ? {
            cta1Type: "Close",
            cta2Type: "Close",
            ctaType: "CTA",
            flowId: flowId || `flow-${uuidv4()}`,
            id: semanticId,
            index: 0,
            isFirstInFlow: true,
            isNew,
            journeyId,
            semanticId,
            semanticPreview: {},
            showX: true,
            stimulusId,
            templateColors: {},
            type,
          }
        : semantics?.find((semantic) => semantic.id === semanticId),
    [flowId, isNew, journeyId, semanticId, semantics, stimulusId, type]
  );

  useEffect(() => {
    if (interaction?.type === "NUDGE" && !triggerConfigs.length) {
      fetchTriggerConfigs({ journeyId });
    }

    if (interaction?.type === "NUDGE" && !triggers.length) {
      fetchTriggers({ journeyId });
    }

    if (interaction?.type === "NUDGE" && !Object.keys(groups).length) {
      fetchGroups({ journeyId });
    }

    if (interaction?.type === "NUDGE" && interaction.isFirstInFlow) {
      fetchSurveys({ journeyId });
      fetchUserStatePaths();
    }
  }, [interaction, journeyId]);

  const journey = useMemo(
    () =>
      journeys.find(
        (journey) => journey.id === journeyId && journey.locale === locale
      ),
    [journeyId, locale, journeys]
  );

  if (
    isSemanticsLoading ||
    isJourneysLoading ||
    !journeys.length ||
    isTriggersLoading ||
    isTriggerConfigsLoading ||
    isFlowRelationsLoading ||
    isGroupsLoading
  ) {
    return <Loader />;
  }

  if ((!isNew && !semantics.length) || !journeys.length || !interaction) {
    return (
      <Box
        alignItems="center"
        display="flex"
        justifyContent="center"
        width="100%"
      >
        <Typography variant="subtitle1">Interaction is not found 😢</Typography>
      </Box>
    );
  }

  const isDisabled = journey.isDisabled;

  const interactions = orderBy(
    semantics
      .filter(({ id, type, flowId }) =>
        type === interaction.type && flowId
          ? flowId === interaction.flowId
          : id === interaction.id
      )
      .map(
        ({
          isFirstInFlow,
          miniTipAutoProgress,
          miniTipAutoProgressOnlyIfIgnored,
          ...semantic
        }) => ({
          ...semantic,
          isFirstInFlow: Boolean(isFirstInFlow),
          miniTipAutoProgressOnlyIfIgnored: Boolean(
            miniTipAutoProgressOnlyIfIgnored
          ),
          miniTipAutoProgress: Boolean(miniTipAutoProgress),
        })
      ),
    ["isFirstInFlow", "createdAt"],
    ["desc", "asc"]
  );

  return (
    <Box display="flex" flexDirection="column" width="100%">
      {isDisabled && (
        <Alert severity="info">
          Digital Attitude Journey interactions is not editable! Copy Journey to
          edit 💡
        </Alert>
      )}
      {isDisabled && (
        <Alert severity="info">
          {`Interaction's Journey is not editable!
          Copy it to edit 💡`}
        </Alert>
      )}
      <Box
        boxSizing="border-box"
        display="flex"
        flexDirection="column"
        height="100%"
        p={4}
        width="100%"
      >
        <Interaction
          interaction={interaction}
          interactions={interactions}
          isDisabled={isDisabled}
          isNew={isNew}
          journey={journey}
          journeyId={journeyId}
          locale={locale}
        />
      </Box>
    </Box>
  );
};

export default EditInteraction;
